import React from 'react'

import { PageLayout, ProductSearchResultsWidget } from '../..'

class ProductSearchResultsPage extends React.Component {
  render() {

    return (
      <PageLayout>
        <div className="row">
          <div className="col-md-12">
            <ProductSearchResultsWidget />
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default ProductSearchResultsPage
