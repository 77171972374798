import React from 'react'

class ProductImage extends React.Component {
  render() {
    const { product, className } = this.props
    const image = +product.id_default_image

    if (image) {
      return (
        <img
          src={`https://assets.shopupnorth.com/p/${image.toString().split('').join('/')}/${image}-home_default.jpg`}
          className={`img-fluid ${className}`}
          alt={product.name} />
      )
    }

    return null
  }
}

export default ProductImage
