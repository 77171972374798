import React from 'react'
// import PropTypes from 'prop-types'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import cartAPI from '../../../services/cart'

class OrderOpenCartListWidget extends React.Component {
  context = null

  state = {
    loading: false,
    disabled: false,
    carts: null,
    page: 1,
    onCompleteCart: null
  }

  componentDidMount() {
    this.setState({ loading: true, disabled: true }, () => {
      cartAPI.getList(this.context, this.state.page).then(result => {
        if (result && result.carts) {
          this.setState({ loading: false, disabled: false, carts: result.carts })
        } else {
          this.setState({ loading: false, disabled: false })
        }
      })
    })
  }

  onCompleteCart = (e, cart) => {
    this.setState({ loading: true, onCompleteCart: +cart.id_cart, disabled: true }, () => {
      cartAPI.completeOrder(this.context, cart.id_cart).then(result => {
        if (result && result.order) {
          cartAPI.getList(this.context, this.state.page).then(result => {
            this.setState({ loading: false, onCompleteCart: null, disabled: false, cart: result && result.carts ? result.carts : null }, () => {
              this.context.addNotification('Gelukt', 'fas fa-check-circle', '')
            })
          })
        } else {
          this.setState({ loading: false, onCompleteCart: null, disabled: false }, () => {
            this.context.addNotification('Mislukt', 'fas fa-exclamation-circle', '')
          })
        }
      })
    })
  }

  renderCart = (cart) => {
    const { disabled, onCompleteCart } = this.state

    return (
      <tr key={`cart-${cart.id_cart}`}>
        <td>
          {cart.id_cart}
        </td>
        <td>
          {cart.customer_name}
        </td>
        <td>
          <button className="btn btn-primary" onClick={e => this.onCompleteCart(e, cart)} disabled={disabled}>
            <i className={`fas ${onCompleteCart === +cart.id_cart ? 'fa-spinner fa-spin' : 'fa-plus'}`}></i> Order aanmaken
          </button>
        </td>
      </tr>
    )
  }

  render() {
    const { loading, carts } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Open Winkelmandjes`} loading={loading} display={0}>
              {carts ? (
                <>
                  <table className="table my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Klant</th>
                        <th>Acties</th>
                      </tr>
                    </thead>
                    <tbody>
                      {carts.map(cart => this.renderCart(cart))}
                    </tbody>
                  </table>
                </>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-info">
                      Geen winkelmandjes gevonden.
                    </div>
                  </div>
                </div>
              )}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

// OrderOpenCartListWidget.propTypes = {}

export default OrderOpenCartListWidget
