import { callAPI } from './api'

export function search(context, query) {
  return callAPI(context, `/search`, 'GET', { query: query })
}

export function filter(context, filters) {
  return callAPI(context, `/filter/products`, 'GET', filters)
}

export default {
  search,
  filter
}
