import React from 'react'

import { PageLayout,
  VoucherCreateWidget, VoucherGeneratePDFWidget } from '../..'

class VoucherPage extends React.Component {
  navs = [
    { id: 'search', icon: 'search', title: 'Zoeken' },
    { id: 'create', icon: 'plus-square', title: 'Aanmaken' },
    { id: 'pdf', icon: 'plus', title: 'PDF' },
  ]

  state = {
    nav: 'search'
  }

  onChangeNav = (e) => {
    e.preventDefault()
    this.setState({ nav: e.target.name })
  }

  renderTab = (nav) => {
    // if (nav === 'search') return null
    if (nav === 'create') return <VoucherCreateWidget />
    if (nav === 'pdf') return <VoucherGeneratePDFWidget />
    return null
  }

  render() {
    const { nav } = this.state

    return (
      <PageLayout>
        <div className="row">
          <div className="col-12 mb-4">
            <ul className="nav nav-pills nav-fill">
              {this.navs.map(n => (
                <li className="nav-item" key={`nav-item-${n.id}`}>
                  <a className={`nav-link ${nav === n.id ? 'active' : ''}`} href="/" onClick={this.onChangeNav} name={n.id}>
                    <i className={`fas fa-${n.icon}`}></i> {n.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          <div className="col-12">
            {this.renderTab(nav)}
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default VoucherPage
