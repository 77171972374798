import { callAPI } from './api'

import DateFormat from 'dateformat'

export function getList(context) {
  return callAPI(context, '/giftregistries', 'GET').then(result => {
    return result && result.giftregistries ? result.giftregistries : null
  })
}

export function get(context, id) {
  return callAPI(context, `/giftregistries/${id}`, 'GET').then(result => {
    return result && result.giftregistry ? result : { giftregistry: null, items: null, orders: null }
  })
}

export function addListItem(context, id, id_product, id_product_attribute, quantity) {
  return callAPI(context, `/giftregistries/${id}/0`, 'POST', {
    id_product: id_product,
    id_product_attribute: id_product_attribute,
    quantity: quantity
  }).then(result => {
    return result && result.items ? result : null
  })
}

export function updateListItem(context, id, id_wishlist_product, quantity) {
  return callAPI(context, `/giftregistries/${id}/${id_wishlist_product}`, 'PUT', {
    quantity: quantity
  }).then(result => {
    return result && result.items ? result : null
  })
}

export function removeListItem(context, id, id_wishlist_product) {
  return callAPI(context, `/giftregistries/${id}/${id_wishlist_product}`, 'DELETE').then(result => {
    return result && result.items ? result : null
  })
}

export function sendOrderConfMailToList(context, id_order, id_wishlist, id_order_customer, id_wishlist_customer) {
  return callAPI(context, `/giftregistries/email/order-confirmation/list-owner`, 'POST', {
    id_order: id_order,
    id_wishlist: id_wishlist,
    id_order_customer: id_order_customer,
    id_wishlist_customer: id_wishlist_customer
  })
}

export function getHistoryForDay(context, date) {
  return callAPI(context, `/history/giftregistries`, 'GET', { date: DateFormat(date, "yyyy-mm-dd")}).then(result => {
    return result && result.history ? result : null
  })
}

export default {
  getList,
  get,
  removeListItem,
  addListItem,
  updateListItem,
  getHistoryForDay,
  sendOrderConfMailToList
}
