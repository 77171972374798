import React from 'react'

import { PageLayout, CampaignPickerConfigurationWidget, CampaignPickerResultView } from '../..'

class CampaignPickerPage extends React.Component {
  state = {
    showPicker: false,
    names: null
  }

  onStartPicker = (names) => {
    this.setState({ showPicker: true, names: names })
  }

  onStopPicker = () => {
    this.setState({ showPicker: false })
  }

  render() {
    const { showPicker, names } = this.state

    if (showPicker) {
      return <CampaignPickerResultView names={names} onStop={this.onStopPicker} />
    }

    return (
      <PageLayout>
        <div className="row">
          <div className="col-12">
            <CampaignPickerConfigurationWidget onStart={this.onStartPicker} />
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default CampaignPickerPage
