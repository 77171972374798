import React from 'react'

import { Widget } from '../..'
import { getPriceLabelUrl } from '../../../services/printer'

class PrinterPriceLabelWidget extends React.Component {
  state = {
    title: null,
    brand: null,
    price: null
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onPrint = (e) => {
    const { title, brand, price } = this.state

    e.preventDefault()
    window.open(getPriceLabelUrl(title, brand, price), "_blank")
    return false
  }

  render() {
    return (
      <Widget title={`Prijs Label`}>
        <form className="">
          <div className="form-group">
            <label>Titel</label>
            <input type="text" className="form-control" name="title" placeholder="titel" onChange={(e) => this.onChange(e)} />
          </div>
          <div className="form-group">
            <label>Merk</label>
            <input type="text" className="form-control" name="brand" placeholder="merk" onChange={(e) => this.onChange(e)} />
          </div>
          <div className="form-group">
            <label>Prijs</label>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">€</span>
              </div>
              <input type="text" className="form-control" name="price" placeholder="prijs" onChange={(e) => this.onChange(e)} />
            </div>
          </div>

          <button className="btn btn-primary btn-block" type="button" onClick={(e) => this.onPrint(e)}><i className="fas fa-print"></i> print</button>
        </form>
      </Widget>
    )
  }
}

export default PrinterPriceLabelWidget
