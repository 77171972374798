import React from 'react'
import PropTypes from 'prop-types'

import stockAPI from '../../../../services/stock'

class ProductSearchWidgetQuickEditQuantity extends React.Component {
  state = {
    quantity: 0,
    out_of_stock: 2,
    syncing: false
  }

  constructor(props) {
    super(props)
    this.state.quantity = props.product.available_quantity
    this.state.out_of_stock = props.product.out_of_stock
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value})
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { quantity, out_of_stock } = this.state
    const { disabled, toggleDisabled, onSuccess, context, product } = this.props
    if (!disabled) {
      this.setState({ syncing: true }, () => {
        toggleDisabled(true)
        stockAPI.updateStock(context, { id_product: product.id_product, id_product_attribute: product.id_combination }, quantity, out_of_stock).then(result => {
          this.setState({ syncing: false }, () => {
            toggleDisabled(false)
            if (result && result.product) {
              onSuccess(result.product)
            }
          })
        })
      })
    }
  }

  render() {
    const { quantity, out_of_stock, syncing } = this.state
    const { onCancel, disabled } = this.props

    return (
      <form className="" onSubmit={this.onSubmit}>
        <div className="row mb-1">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-edit"></i>
                </span>
              </div>
              <input type="text" autoFocus className="form-control" placeholder="Hoeveelheid" name="quantity" value={quantity} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fas fa-boxes"></i>
                </span>
              </div>
              <select class="custom-select" name="out_of_stock" value={out_of_stock} onChange={this.onChange} disabled={disabled}>
                <option value="0">Backorders niet toestaan</option>
                <option value="1">Backorders toestaan</option>
                <option value="2">Backorders toestaan (default)</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-12">
            <button className="btn btn-outline-success" type="submit" disabled={disabled ? 'disabled' : ''}>
              <i className={`fas ${syncing ? 'fa-spinner fa-spin' : 'fa-check'}`}></i> Opslaan
            </button>
            <button className="btn btn-outline-danger" type="button" onClick={onCancel} disabled={disabled ? 'disabled' : ''}>
              <i className="fas fa-window-close"></i>
            </button>
          </div>
        </div>
      </form>
    )
  }
}

ProductSearchWidgetQuickEditQuantity.propTypes = {
  product: PropTypes.object,
  context: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  toggleDisabled: PropTypes.func
}

export default ProductSearchWidgetQuickEditQuantity
