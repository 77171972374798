import React from 'react'
import { TextAreaEditor } from '../..'

class ProductEditWidgetLocale extends React.Component {
  onChange = (name, data) => {
    const { onProductChange } = this.props
    onProductChange({ target: { name: name, value: data }})
  }

  render() {
    const { onProductChange, disabled, visible, product, lang } = this.props
    const slug = lang.title.toLowerCase()

    return (
      <form className={visible ? 'd-block' : 'd-none'}>
        <div className="form-group row">
          <label htmlFor={`fname_${slug}`} className="col-sm-2 col-form-label text-right">Titel</label>
          <div className="col-sm-10">
            <input type="text" name="name" value={product[`name_${slug}`]} name={`name_${slug}`} id={`fname_${slug}`} className="form-control" placeholder="Titel" disabled={disabled} onChange={onProductChange} />
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Omschrijving</label>
          <div className="col-sm-10">
            <TextAreaEditor
              name={`description_${slug}`}
              content={product[`description_${slug}`]}
              disabled={disabled}
              onChange={this.onChange} />
            <div className="text-right"><small><i>{product[`description_${slug}`].length} van max 6000 karakters</i></small></div>
          </div>
        </div>

        <div className="form-group row">
          <label className="col-sm-2 col-form-label text-right">Samenvatting<br /><small>Gebruikt wanneer omschrijving te lang is. Indien leeg, wordt omschrijving gebruikt.</small></label>
          <div className="col-sm-10">
            <TextAreaEditor
              name={`description_short_${slug}`}
              content={product[`description_short_${slug}`]}
              disabled={disabled}
              onChange={this.onChange} />
            <div className="text-right"><small><i>{product[`description_short_${slug}`].length} van max 800 karakters</i></small></div>
          </div>
        </div>
      </form>
    )
  }
}

export default ProductEditWidgetLocale
