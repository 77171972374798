import React from 'react'

import { Widget, ProductSearchProduct } from '../..'
import AppContext from '../../../context/App/Context'

class ProductSearchResultsWidget extends React.Component {
  context = null

  state = {
    addingToCart: false
  }

  render() {
    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          const { search } = context
          const products = (Array.isArray(search) && search.length > 0) ? search : null

          return (
            <Widget title={`Resultaten`} display={3}>

              {products ? (
                <table className="table mb-0">
                  <tbody>
                    {products.map((product, idx) => (
                      <tr key={`productsearch-result-${idx}`}>
                        <td className="">
                          <ProductSearchProduct product={product} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-warning mb-0" role="alert">Geen resultaten</div>
              )}

            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default ProductSearchResultsWidget
