import React from 'react'

import { PageLayout, StoreProvider, StoreDailySalesWidget } from '../..'
import AppContext from '../../../context/App/Context'

class StorePage extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {context => {
          return (
            <PageLayout>
              <StoreProvider app={context}>
                <div className="row">
                </div>
                <div className="row">
                  <div className="col-12">
                    <StoreDailySalesWidget />
                  </div>
                </div>
              </StoreProvider>
            </PageLayout>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default StorePage
