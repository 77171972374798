import React from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'gatsby'
import DateFormat from 'dateformat'
import DatePicker from 'react-datepicker'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import giftregistryAPI from '../../../services/giftregistry'

class GiftRegistryHistoryWidget extends React.Component {
  context = null

  state = {
    disabled: false,
    loading: false,
    history: null,
    date: new Date()
  }

  onChangeDate = (date) => {
    this.setState({ date: date }, () => {
      this.loadHistory()
    })
  }

  loadHistory = () => {
    this.setState({ loading: true, disabled: true }, () => {
      giftregistryAPI.getHistoryForDay(this.context, this.state.date).then(result => {
        if (result && result.history) {
          this.setState({ loading: false, disabled: false, history: result.history })
        } else {
          this.setState({ loading: false, disabled: false })
        }
      })
    })
  }

  componentDidMount() {
    this.loadHistory()
  }

  renderHistoryEventName(name) {
    if (name === 'item-added') {
      return <span className="badge badge-success"><i className="fas fa-plus"></i> Item toegevoegd</span>
    } else if (name === 'item-deleted') {
      return <span className="badge badge-danger"><i className="fas fa-minus"></i> Item verwijderd</span>
    } else if (name === 'item-updated') {
      return <span className="badge badge-primary"><i className="fas fa-edit"></i> Item aangepast</span>
    }
  }

  render() {
    const { loading, history, date, disabled } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Historiek`} display={0} loading={loading}>
              <form>
                <div className="row">
                  <div className="col-12">

                    <div className="form-group row">
                      <label htmlFor="inputDate" className="col-sm-2 col-form-label">Datum</label>
                      <div className="col-sm-10">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <div className="input-group-text"><i className="fas fa-calendar-day"></i></div>
                          </div>
                          <DatePicker
                            id="inputDate"
                            className="form-control"
                            placeholderText="Datum"
                            dateFormat="dd/MM/yyyy"
                            selected={date}
                            disabled={disabled}
                            onChange={this.onChangeDate} />
                          <div className="input-group-append">
                            <button className="btn btn-outline-secondary" type="button" onClick={this.loadHistory} disabled={disabled}>
                              <i className="fas fa-sync"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </form>
              {history && history.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Event</th>
                      <th>Datum</th>
                      <th>Lijst</th>
                      <th>Klant</th>
                      <th>Product</th>
                      <th>Data</th>
                      <th>Door</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history.map(i => {
                      const date_add = new Date(i.date_add)

                      return (
                        <tr key={`history-${i.id_event}`}>
                          <td>
                            {this.renderHistoryEventName(i.event_name)}
                          </td>
                          <td>
                            {DateFormat(date_add, "dd/mm/yyyy HH:MM:ss")}
                          </td>
                          <td>
                            {i.list_name}
                          </td>
                          <td>
                            {i.list_customer_firstname} {i.list_customer_lastname}
                          </td>
                          <td>
                            {i.product_name}
                          </td>
                          <td>
                            {i.event_data}
                          </td>
                          <td>
                            {i.customer_email ? (
                              <span className="badge badge-primary"><i className="fas fa-user"></i> {i.customer_email}</span>
                            ) : (
                              <span className="badge badge-danger"><i className="fas fa-user-cog"></i> {i.admin_email}</span>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              ) : (
                <div className="alert alert-info" role="alert">
                  Voor deze datum is er geen historiek.
                </div>
              )}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

// GiftRegistryHistoryWidget.propTypes = {}

export default GiftRegistryHistoryWidget
