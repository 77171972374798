import React from 'react'
import PropTypes from 'prop-types'
import DateFormat from 'dateformat'
import { Link } from 'gatsby'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import ordersAPI from '../../../services/order'
// import { l } from '../../../utils/lang'
import { formatPrice } from '../../../utils/formatting'

const DISPLAY_WEB_SHIP = 1
const DISPLAY_WEB_PICKUP = 2
const DISPLAY_WEB_PARENTS = 3
const DISPLAY_WEB_BOLCOM = 4

const CARRIER_SHIP = 1
const CARRIER_PICKUP = 2
const CARRIER_PARENTS = 3
const CARRIER_BOLCOM = 4

const tabs = [
  { display: DISPLAY_WEB_SHIP, carrier: CARRIER_SHIP, title: 'te versturen', icon: 'fas-truck' },
  { display: DISPLAY_WEB_PICKUP, carrier: CARRIER_PICKUP, title: 'pickup in winkel', icon: null },
  { display: DISPLAY_WEB_PARENTS, carrier: CARRIER_PARENTS, title: 'pickup door ouders', icon: null },
  { display: DISPLAY_WEB_BOLCOM, carrier: CARRIER_BOLCOM, title: 'via bol.com', icon: null }
]

const STATUS_OPEN = 2
// const STATUS_PROCESSING = 3
// const STATUS_SHIPPED = 4
// const STATUS_CLOSED = 5

class OrderListWidget extends React.Component {
  context = null

  state = {
    // data
    orders: null,
    carrier: CARRIER_SHIP,
    status: STATUS_OPEN,
    // flags
    loading: false
  }

  setTab = (e, carrier) => {
    e.preventDefault()
    this.setState({ carrier: carrier }, () => {
      this.loadOrders()
    })
  }

  loadOrders = () => {
    const { carrier, status } = this.state

    this.setState({ loading: true, orders: null }, () => {
      ordersAPI.getWebOrders(this.context, carrier, status).then(result => {
        if (result && result.orders) {
          this.setState({ loading: false, orders: result.orders })
        } else {
          this.setState({ loading: false })
        }
      })
    })
  }

  componentDidMount() {
    this.loadOrders()
  }

  renderOrderRow(order) {
    return (
      <tr key={`order-${order.id_order}`}>
        <td>
          {order.reference}<br />
          #{order.id_order}
        </td>
        <td>
          {DateFormat(order.date_add, "dd/mm/yyyy")}<br />
          {DateFormat(order.date_add, "HH:mm:ss")}
        </td>
        <td>
          {order.firstname} {order.lastname}<br />
          {order.email}
        </td>
        <td>
          {formatPrice(order.total_paid_real)}<br />
          <span className="badge" style={{ backgroundColor: order.current_state_color}}>{order.current_state_name}</span>
        </td>
        <td>{order.delay}<br />{order.carrier}</td>
        <td>{order.module}</td>
        <td className="text-right">
          {order.wishlist_name
            ? <span className="badge badge-primary mr-1">{order.wishlist_name}</span>
            : <i className={`fas fa-times text-secondary mr-1`}></i> }
          {order.gift
            ? <i className={`fas fa-gift text-primary mr-1`}></i>
            : <i className={`fas fa-times text-secondary mr-1`}></i> }
          {order.gift_message
            ? <i className={`fas fa-comment text-primary mr-1`}></i>
            : <i className={`fas fa-times text-secondary mr-1`}></i> }
        </td>
        <td>
          <Link className="btn btn-primary btn-sm" to={`/orders?id=${order.id_order}`}>
            <i className="fas fa-chevron-right mx-2"></i>
          </Link>
        </td>
      </tr>
    )
  }

  renderTitle() {
    const { carrier } = this.state

    return (
      <>
        <ul className="nav nav-tabs mb-0" role="tablist">
          {tabs.map(tab => (
            <li className="nav-item" key={`nav-item-${tab.carrier}`}>
              <a className={`nav-link ${+carrier === +tab.carrier ? 'active' : ''}`} href="/" title={tab.title} onClick={e => this.setTab(e, tab.carrier)}>
                {tab.icon ? <i className={`fas ${tab.icon}`}></i> : null}{tab.title}
              </a>
            </li>
          ))}
        </ul>
      </>
    )
  }

  render() {
    const { orders, loading } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={this.renderTitle()} loading={loading} display={2}>
              {orders ? (
                <>
                  <table className="table my-0">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Datum</th>
                        <th>Klant</th>
                        <th>Status</th>
                        <th>Verzending</th>
                        <th>Betaling</th>
                        <th className="text-right">
                          <i className={`fas fa-gifts mr-1`}></i>
                          <i className={`fas fa-gift mr-1`}></i>
                          <i className={`fas fa-comment`}></i>
                        </th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.map(order => this.renderOrderRow(order))}
                    </tbody>
                  </table>
                </>
              ) : (
                <></>
              )}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

OrderListWidget.propTypes = {
}

export default OrderListWidget
