import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import queryString from 'query-string'

import AppContext from '../../../context/App/Context'
import { PageLayout, BrandNavbar } from '../..'

class BrandReportingPage extends React.Component {
  render() {
    return (
      <AppContext.Consumer>
        {context => {
          return (
            <PageLayout>
              <BrandNavbar />
            </PageLayout>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

BrandReportingPage.propTypes = {}

export default BrandReportingPage
