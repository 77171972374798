import React from 'react'
import { Link } from 'gatsby'

import { Navbar } from '../..'

class ProductNavbar extends React.Component {
  render() {
    return (
      <Navbar
        className="mb-2"
        title="fa-archive"
        link="/producten"
        items={[
          { title: 'Producten', link: '/producten' },
          { title: 'Aanmaken', link: '/producten?action=add' }
        ]} />
    )
  }
}

export default ProductNavbar
