import { callAPI } from './api'

export const getManufacturerTotalSales = (context) => callAPI(context, `/statistics/manufacturer`, 'GET')
export const getManufacturerYearlySales = (context, year, manufacturer) => callAPI(context, `/statistics/manufacturer/yearly?${year ? `year=${year}` : ''}${manufacturer ? `&manufacturer=${manufacturer}` : ''}`, 'GET')
export const getManufacturerMonthlySales = (context, year, month, manufacturer) => callAPI(context, `/statistics/manufacturer/monthly?${year ? `year=${year}` : ''}${month ? `&month=${month}` : ''}${manufacturer ? `&manufacturer=${manufacturer}` : ''}`, 'GET')
export const getManufacturerOrderProducts = (context, manufacturer, year, month) => callAPI(context, `/statistics/manufacturer/ordered-products?manufacturer=${manufacturer}&year=${year}&month=${month}`, 'GET')
export const getYTDStatistics = (context) => callAPI(context, `/statistics/ytd`, 'GET')
export const getYTDMonthlyStatistics = (context) => callAPI(context, `/statistics/ytd/months`, 'GET')

export const getDailyWebOrderStatistics = (context) => callAPI(context, `/statistics/web/daily`, 'GET')
export const getTopWebOrderStatistics = (context) => callAPI(context, `/statistics/web/top`, 'GET')

export const getDailyStoreStatistics = (context) => callAPI(context, `/statistics/store/daily`, 'GET')
export const getTopStoreStatistics = (context) => callAPI(context, `/statistics/store/top`, 'GET')

export default {
  getManufacturerTotalSales,
  getManufacturerYearlySales,
  getManufacturerMonthlySales,
  getManufacturerOrderProducts,
  getYTDStatistics,
  getYTDMonthlyStatistics,
  getDailyWebOrderStatistics,
  getTopWebOrderStatistics,
  getDailyStoreStatistics,
  getTopStoreStatistics
}
