import React from 'react'
import PropTypes from 'prop-types'

import { formatPrice } from '../../../utils/formatting'

class OrderDetailsWidgetTabDetail extends React.Component {
  render() {
    const { order } = this.props

    return (
      <>
        <div className="row">
          <div className="col-md-3 col-6">
            <div className="card">
              <div className="card-body text-center">
                {order.date_add}
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="card">
              <div className="card-body text-center">
                {formatPrice(order.total_paid_real)}
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="card">
              <div className="card-body text-center">
                {order.date_add}
              </div>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <div className="card">
              <div className="card-body text-center">
                {order.associations.order_rows.length} producten
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          
        </div>

        <div className="row mt-2">
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Status
              </div>
              <div className="card-body">
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card">
              <div className="card-header">
                Documents
              </div>
              <div className="card-body">
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

OrderDetailsWidgetTabDetail.propTypes = {
  order: PropTypes.object
}

export default OrderDetailsWidgetTabDetail
