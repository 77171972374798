import React from 'react'

import { StoreContext } from '../..'
import storeAPI from '../../../services/store'

class StoreProvider extends React.Component {
  state = {
    // data
    items: null,
    // flags
    disabled: false,
    loading: false,
    // actions
    getDailyProductSales: null
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  getDailyProductSales = () => {
    this.setState({ loading: true, disabled: true }, () => {
      storeAPI.getDailyProductSales(this.props.app).then(result => {
        this.setState({ loading: false, disabled: false, items: result && result.items ? result.items : null })
      })
    })
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(props) {
    super(props)

    this.state.getDailyProductSales = this.getDailyProductSales
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  componentDidMount() {
    this.getDailyProductSales()
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  render() {
    return (
      <StoreContext.Provider value={ this.state }>
        {this.props.children}
      </StoreContext.Provider>
    )
  }
}

export default StoreProvider
