import React from 'react'
import Helmet from 'react-helmet'

import { PageHeader, PageSideBarLeft, LoginPage, NotificationList } from '../..'
import AppContext from '../../../context/App/Context'

import './styles/v1/fonts.css'
import './styles/v1/base.scss'

class PageLayout extends React.Component {
  state = {
    sidebarOpen: true
  }

  onToggle = () => {
    this.setState({ sidebarOpen: this.state.sidebarOpen ? false : true })
  }

  render() {
    const { children, hasAccess } = this.props
    const { sidebarOpen } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          if (!context || !context.token || !context.user) {
            return (
              <LoginPage />
            )
          }

          return (
            <>
              <Helmet>
                <link href="//fonts.googleapis.com/css?family=Roboto:100,400,500,500i,900%7CArchivo+Narrow%7CAbhaya+Libre" rel="stylesheet" />
              </Helmet>

              <PageHeader context={context} />

              <div className="container-fluid page">
                <div className="row">
                  <PageSideBarLeft className={sidebarOpen ? 'col-1 col-xl-2' : 'col-1'} context={context} open={sidebarOpen} onToggle={this.onToggle} />

                  <main className={`${sidebarOpen ? 'col-11 col-xl-10' : 'col-11'} ml-sm-auto`}>
                    {(hasAccess && (!context || !context.user || !hasAccess(context))) ? (
                      <div className="alert alert-danger">Je hebt geen toegang tot deze pagina, sorry</div>
                    ) : (
                      <>
                        <NotificationList context={context} />
                        {children}
                      </>
                    )}

                  </main>
                </div>
              </div>
            </>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default PageLayout
