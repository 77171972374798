import React from 'react'
import PropTypes from 'prop-types'

import productAPI from '../../../../services/product'

class ProductSearchWidgetQuickEditAvailable extends React.Component {
  state = {
    available_now_nl: '',
    available_later_nl: '',
    available_now_en: '',
    available_later_en: '',
    syncing: false
  }

  constructor(props) {
    super(props)
    this.state.available_now_nl = props.product.available_now_nl
    this.state.available_later_nl = props.product.available_later_nl
    this.state.available_now_en = props.product.available_now_en
    this.state.available_later_en = props.product.available_later_en
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value})
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { available_now_nl, available_later_nl, available_now_en, available_later_en } = this.state
    const { disabled, toggleDisabled, onSuccess, context, product } = this.props
    if (!disabled) {
      this.setState({ syncing: true }, () => {
        toggleDisabled(true)

        productAPI.updateProductAvailable(context, product.id_product, product.id_combination, available_now_en, available_now_nl, available_later_en, available_later_nl).then(result => {
          this.setState({ syncing: false }, () => {
            toggleDisabled(false)
            if (result && result.product) {
              onSuccess(result.product)
            }
          });
        });
      });
    }
  }

  render() {
    const { available_now_nl, available_later_nl, available_now_en, available_later_en, syncing } = this.state
    const { onCancel, disabled } = this.props

    return (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="row mb-1">
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">available_now_en</span>
              </div>
              <input autoFocus type="text" className="form-control" placeholder="available_now_en" name="available_now_en" value={available_now_en} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">available_later_en</span>
              </div>
              <input autoFocus type="text" className="form-control" placeholder="available_later_en" name="available_later_en" value={available_later_en} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
        </div>

        <div className="row mb-1">
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">available_now_nl</span>
              </div>
              <input autoFocus type="text" className="form-control" placeholder="available_now_nl" name="available_now_nl" value={available_now_nl} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">available_later_nl</span>
              </div>
              <input autoFocus type="text" className="form-control" placeholder="available_later_nl" name="available_later_nl" value={available_later_nl} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-secondary" onClick={onCancel} disabled={disabled}>
              <i className="fas fa-times"></i> annuleren
            </button>
            <button type="submit" className="btn btn-primary ml-1" disabled={disabled}>
              <i className={`fas ${syncing ? 'fa-spinner fa-spin' : 'fa-check'}`}></i> bewaren
            </button>
          </div>
        </div>
      </form>
    )
  }
}

ProductSearchWidgetQuickEditAvailable.propTypes = {
  product: PropTypes.object,
  context: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  toggleDisabled: PropTypes.func
}

export default ProductSearchWidgetQuickEditAvailable
