import { callAPI } from './api'

export function getList(context) {
  return callAPI(context, `/manufacturer`, 'GET', { display: 'full' }).then(result => {
    return result
  })
}

export function get(context, id) {
  return callAPI(context, `/manufacturer/${id}`, 'GET').then(result => {
    if (result && result.manufacturer) return result.manufacturer
    return null
  })
}

export default {
  getList,
  get
}
