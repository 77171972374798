import * as config from '../config/config.json'

export function getPriceLabelPDFUrl(product) {
  return `${config.default.api.url}/print/product/${product.id_product}/${product.id_combination ? product.id_combination : '0'}/label`
}


export function getPriceLabelUrl(title, brand, price) {
  return `${config.default.api.url}/print/label/small?title=${title}&brand=${brand}&price=${price}`
}

export function getSmallLabelUrl(price) {
  return `${config.default.api.url}/print/label/mini?price=${price}`
}

export function getJewelleryLabelUrl(price, code) {
  return `${config.default.api.url}/print/label/jewellery?price=${price}&code=${code}`
}


export function getProductPriceLabelUrl(product) {
  const brand = product.name_manufacturer
  const title = product.name
  return `${config.default.api.url}/print/label/small?title=${title}&brand=${brand}&price=${Number(product.fullPrice).toFixed(2)}`
}

export function getProductSmallLabelUrl(product) {
  return `${config.default.api.url}/print/label/mini?price=${Number(product.fullPrice).toFixed(2)}`
}

export function getProductJewelleryLabelUrl(product) {
  const code = product.ean13 ? product.ean13 : ''
  const reference = product.reference ? product.reference : ''
  return `${config.default.api.url}/print/label/jewellery?price=${Number(product.fullPrice).toFixed(2)}&code=${code}&reference=${reference}&id_product=${product.id_product}`
}

export default {
  getPriceLabelPDFUrl,
  getPriceLabelUrl,
  getSmallLabelUrl,
  getJewelleryLabelUrl,
  getProductPriceLabelUrl,
  getProductSmallLabelUrl,
  getProductJewelleryLabelUrl
}
