import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { NavbarDropdown, ProductSearchForm } from '..'

class Navbar extends React.Component {
  clearSelected = (e) => {
    const { setSelected } = this.props
    if (setSelected) {
      setSelected(null)
    }
  }

  render() {
    const { className, title, link, items } = this.props
    const currentLocation = window && window.location ? window.location : ''

    return (
      <nav className={`navbar navbar-expand-md navbar-light bg-light flex-md-nowrap ${className}`}>
        <Link className="navbar-brand" to={link} onClick={this.clearSelected}>
          <i className={`fas ${title}`}></i>
        </Link>

        {items && items.length > 0 ? (
          <div className="collapse navbar-collapse">
            <ul className="navbar-nav">
              {items.map((item, idx) => (
                <li className={`nav-item ${currentLocation && currentLocation.pathname === item.link ? 'active' : ''}`} key={`navbar-nav-${idx}`}>
                  <Link className="nav-link" to={item.link} onClick={this.clearSelected}>
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </nav>
    )
  }
}

Navbar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  items: PropTypes.array,
  setSelected: PropTypes.func
}

export default Navbar
