import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { USERS_WITHOUT_ACCESS } from '../../../constants/access'

import './sidebar.scss'

const hasAccess = (ctx) => {
  if (USERS_WITHOUT_ACCESS.includes(+ctx.user.id_employee)) return false
  return true
}

const items = [
  { link: '/', title: 'Dashboard', icon: 'fa-tachometer' },
  { link: '/orders', title: 'Orders', icon: 'fa-box-open' },
  { link: '/producten', title: 'Producten', icon: 'fa-archive' },
  { link: '/merken', title: 'Merken', icon: 'fa-archive' },
  { link: '/klanten', title: 'Klanten', icon: 'fa-users' },
  { link: '/cadeaulijsten', title: 'Cadeaulijsten', icon: 'fa-gifts' },
  { link: '/vouchers', title: 'Vouchers', icon: 'fa-gifts' },
  { link: '/winkel', title: 'Winkel', icon: 'fa-store' },
  { link: '/reports', title: 'Rapporten', icon: 'fa-chart-pie', filter: (c) => hasAccess(c) },
  { link: '/reports/webshop', title: 'Webshop Cijfers', icon: 'fa-chart-pie', filter: (c) => hasAccess(c) },
  { link: '/reports/store', title: 'Winkel Cijfers', icon: 'fa-chart-pie', filter: (c) => hasAccess(c) },
  { link: '/marketing', title: 'Marketing', icon: 'fa-cog' },
  { link: '/printer', title: 'Printer', icon: 'fa-tachometer-alt' }
]

class PageSideBarLeft extends React.Component {
  state = {
    pathname: ''
  }

  onToggle = (e) => {
    e.preventDefault()
    this.props.onToggle()
  }

  navigateTo = (e) => {
  }

  componentDidMount() {
    this.setState({ pathname: window && window.location ? window.location.pathname : '' })
  }

  render() {
    const { className, open, context } = this.props
    const { pathname } = this.state

    const showCloseButton = false

    return (
      <nav className={`sidebar noprint ${className} ${open ? 'open' : 'closed'}`}>
        <div className="sidebar-sticky">
          <div className="list-group">
            {items.map((item, idx) => {
              if (item.filter && !item.filter(context)) return ''

              return (
                <Link key={`list-group-item-${idx}`} className={`list-group-item list-group-item-action ${item.link === pathname ? 'active' : ''}`} to={item.link} title={item.title} onClick={this.navigateTo}>
                  <i className={`fas ${item.icon}`}></i><span className="ml-1 nav-link-text d-none d-xl-inline-block">{item.title}</span>
                </Link>
              )
            })}

            {showCloseButton ? (
              <a href="/" className="list-group-item list-group-item-action" onClick={this.onToggle}>
                <i className={`fas ${open ? 'fa-caret-left' : 'fa-caret-right'}`}></i><span className="ml-1 nav-link-text">{open ? 'sluiten' : 'open'}</span>
              </a>
            ) : null}
          </div>
        </div>
      </nav>
    )
  }
}

PageSideBarLeft.propTypes = {
  className: PropTypes.string,
  onToggle: PropTypes.func,
  open: PropTypes.bool
}

export default PageSideBarLeft
