import React from 'react'
import PropTypes from 'prop-types'

export const STYLE_WIDGET = 0
export const STYLE_LISTGROUP = 1
export const STYLE_TABS = 2
export const STYLE_TABLE = 3

class Widget extends React.Component {
  onClose = (e) => {
    e.preventDefault()
    if (this.props.onClose) this.props.onClose()
  }

  renderBody() {
    const { children, display } = this.props

    if (display === STYLE_LISTGROUP) {
      return (
        <div className="list-group list-group-flush">
          {children}
        </div>
      )
    } else if (display === STYLE_TABS) {
      return (
        <div className="card-body">
          {children}
        </div>
      )
    } else if (display === STYLE_TABLE) {
      return (
        <div className="card-body p-0">
          {children}
        </div>
      )
    }

    return (
      <div className="card-body">
        {children}
      </div>
    )
  }

  render() {
    const { title, loading, display, onClose, footer } = this.props

    const isTabs = (display === STYLE_TABS)

    return (
      <div className="card">
        <div className={`card-header ${isTabs ? 'pb-0' : ''}`}>
          <div className="row">
            <div className="col-md-11">
              {loading ? <i className={`fas fa-spinner fa-spin float-left mr-2`}></i> : null}
              {isTabs ? title : <h6 className="mb-0">{title}</h6>}
            </div>
            <div className="col-md-1 text-right">
              {onClose ? <a href="/" onClick={this.onClose}><i className={`fas fa-times mr-2`}></i></a> : null}
            </div>
          </div>
        </div>
        {this.renderBody()}
        {footer ? (
          <div className="card-footer">
            {footer}
          </div>
        ) : null}
      </div>
    )
  }
}

Widget.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  display: PropTypes.number,
  onClose: PropTypes.func,
  footer: PropTypes.node
}

export default Widget
