import React from 'react'
import { Link } from 'gatsby'
import queryString from 'query-string'
import Tooltip from 'rc-tooltip'

import { Widget, ProductSearchWidgetQuickEdit } from '../..'
import AppContext from '../../../context/App/Context'
import printerAPI from '../../../services/printer'
import manufacturerAPI from '../../../services/manufacturer'
import productsearchAPI from '../../../services/productsearch'
import productAPI from '../../../services/product'
import vatAPI from '../../../services/vat'

import 'rc-tooltip/assets/bootstrap_white.css';

const TYPE_QUICKEDIT_QUANTITY = 1
const TYPE_QUICKEDIT_TITLE = 2
const TYPE_QUICKEDIT_PRICE = 3
const TYPE_QUICKEDIT_AVAILABLE = 4

const OUTOFSTOCK_ALLOW = 1
const OUTOFSTOCK_DISALLOW = 0
const OUTOFSTOCK_DEFAULT = 2

class ProductSearchWidget extends React.Component {
  state = {
    disabled: false,
    loading: false,
    searching: false,
    products: null,
    manufacturers: null,
    taxrules: null,
    query: { changed: false, value: '' },
    ean13: { changed: false, value: '' },
    id_product: { changed: false, value: '' },
    reference: { changed: false, value: '' },
    id_manufacturer: { changed: false, value: '' },
    id_category: { changed: false, value: '' },
    quickedit: null,
    product: null,
    changing: null
  }

  onSearch = (e) => {
    if (e) e.preventDefault()

    if (!this.state.searching) {
      this.setState({ products: null, searching: true, changing: false, disabled: true }, () => {
        const params = []
        if (this.state.query.changed) params['query'] = this.state.query.value
        if (this.state.id_product.changed) params['id'] = this.state.id_product.value
        if (this.state.ean13.changed) params['ean'] = this.state.ean13.value
        if (this.state.reference.changed) params['code'] = this.state.reference.value
        if (this.state.id_manufacturer.changed) params['manufacturer'] = this.state.id_manufacturer.value
        if (this.state.id_category.changed) params['category'] = this.state.id_category.value

        productsearchAPI.filter(this.context, params)
          .then(products => this.setState({ products: products, loading: false, searching: false, disabled: false }))
      })
    }
  }

  onReset = (e) => {
    e.preventDefault()

    this.setState({
      disabled: false,
      searching: false,
      loading: false,
      products: null,
      query: { changed: false, value: '' },
      ean13: { changed: false, value: '' },
      id_product: { changed: false, value: '' },
      reference: { changed: false, value: '' },
      id_manufacturer: { changed: false, value: '' },
      id_category: { changed: false, value: '' }
    })
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: { changed: true, value: e.target.value } })
  }

  onQuickEdit = (e, product, type) => {
    if (e) e.preventDefault()
    this.setState({
      product: product,
      quickedit: type
    })
  }

  onProductUpdated = (product) => {
    const { context } = this
    const products = this.state.products.map(p => {
      return (+p.id_product === +product.id_product && +p.id_combination === +product.id_combination)
        ? product
        : p
    })

    this.setState({
      products: products,
      product: null,
      quickedit: null
    })
    context.addNotification('Gelukt!', null, 'Product aangepast', 1) // @todo change constants
  }

  onPrintPriceLabel = (e, product) => {
    e.preventDefault()
    window.open(printerAPI.getPriceLabelPDFUrl(product), "_blank").print()
    return false
  }

  onPrintSmallLabel = (e, product) => {
    e.preventDefault()
    window.open(printerAPI.getSmallLabelUrl(Number(product.fullPrice).toFixed(2)), "_blank").print()
    return false
  }

  onPrintJewelleryLabel = (e, product) => {
    e.preventDefault()
    window.open(printerAPI.getProductJewelleryLabelUrl(product), "_blank").print()
    return false
  }

  onSetField = (e, product, field, value) => {
    e.preventDefault()

    const { context } = this
    this.setState({ disabled: true, changing: product }, () => {
      productAPI.updateProductField(context, product.id_product, product.id_combination, field, value).then(result =>  {
        if (result && result.product) {
          const products = this.state.products.map(p => {
            if (+result.product.id_product === +p.id_product) {
              if (p.id_combination && +p.id_combination === +result.product.id_combination) {
                return result.product
              } else if (!p.id_combination) {
                return result.product
              }
            }
            return p
          })
          this.setState({ disabled: false, changing: null, products: products })
          context.addNotification('Gelukt!', null, 'Product aangepast', 1) // @todo change constants
        } else {
          this.setState({ disabled: false, changing: null }) // @todo add error
        }
      })
    })
  }

  toggleDisabled = (disabled) => this.setState({ disabled: disabled })

  onSelectProduct = (e, id) => {
    this.props.onSelectProduct(+id, this.onSearch)
  }

  reloadSearch = () => {
    const params = queryString.parse(window.location.search)
    if (params.s && params.s.length > 2 && params.s !== this.state.query.value) {
      this.setState({ query: { changed: true, value: params.s } }, () => {
        this.onSearch()
      })
    }
  }

  componentDidMount() {
    this.setState({ loading: true, disabled: true }, () => {
      manufacturerAPI.getList(this.context).then(result => {
        this.setState({ loading: false, disabled: false, manufacturers: result }, () => {
          this.reloadSearch()
        })
      })

      vatAPI.getTaxRules(this.context).then(result => {
        if (result && result.taxrules) {
          this.setState({ taxrules: result.taxrules })
        }
      })
    })
  }

  componentDidUpdate(prevProps) {
    this.reloadSearch()
  }

  renderProduct(product) {
    const { disabled, changing, quickedit, taxrules } = this.state

    const giftlist_quantity_left = +product.giftlist_quantity - +product.giftlist_quantity_sold > 0
      ? +product.giftlist_quantity - +product.giftlist_quantity_sold
      : 0

    return (
      <div className="row">
        <div className="col-1">
          {product.id_image ? (
            <img src={`https://assets.shopupnorth.com/p/${product.id_image.toString().split('').join('/')}/${product.id_image}-home_default.jpg`} className="img-fluid" alt="" />
          ) : ''}
        </div>
        {quickedit && product.id_product === this.state.product.id_product && product.id_combination === this.state.product.id_combination ? (
          <div className="col-11 py-4">
            <ProductSearchWidgetQuickEdit
              product={product}
              taxrules={taxrules}
              type={quickedit}
              context={this.context}
              onCancel={this.onQuickEdit}
              onSuccess={this.onProductUpdated}
              disabled={disabled}
              toggleDisabled={this.toggleDisabled} />
          </div>
        ) : (
          <>
            <div className="col-6 pt-2">
              <h4>
                <Link to={`/producten`} onClick={e => this.onQuickEdit(e, product, TYPE_QUICKEDIT_TITLE)} disabled={true} className="">
                  <small>#{product.id_product}</small> {product.combination ? `${product.combination} - ` : ''}{product.name}
                </Link>
                <Tooltip placement="top" trigger={['hover']} overlay={<span>totaal beschikbare hoeveelheid</span>}>
                  <button className="btn btn-link pl-0" onClick={e => this.onQuickEdit(e, product, TYPE_QUICKEDIT_QUANTITY)}>
                    <span className={`ml-1 badge ${product.available_quantity > 1 ? 'badge-success' : product.available_quantity > 0 ? 'badge-warning' : 'badge-danger'}`}>
                      {product.available_quantity}
                    </span>
                  </button>
                </Tooltip>
                <Tooltip placement="top" trigger={['hover']} overlay={<span>totaal resterend op lijst</span>}>
                  <button className="btn btn-link pl-0">
                    <span className={`badge ${giftlist_quantity_left === 0 ? 'badge-light' : giftlist_quantity_left > product.available_quantity ? 'badge-danger' : 'badge-success '}`}>
                      <i className="fas fa-gifts"></i> {+giftlist_quantity_left}
                    </span>
                  </button>
                </Tooltip>
              </h4>
              <p>{product.name_manufacturer} | {product.ean13} - {product.reference} - {product.supplier_reference}</p>
              <p>
                <Link to={`/producten`} onClick={e => this.onQuickEdit(e, product, TYPE_QUICKEDIT_QUANTITY)} disabled={true} className="">
                  {+product.out_of_stock === OUTOFSTOCK_DISALLOW && <><i className="fas fa-times-circle"></i> Backorders niet toestaan</>}
                  {+product.out_of_stock === OUTOFSTOCK_ALLOW && <><i className="fas fa-check-circle"></i> Backorders toestaan</>}
                  {+product.out_of_stock === OUTOFSTOCK_DEFAULT && <><i className="fas fa-check-square"></i> Backorders toestaan</>}
                </Link>
                &nbsp;-&nbsp;
                <Link to={`/producten`} onClick={e => this.onQuickEdit(e, product, TYPE_QUICKEDIT_AVAILABLE)} disabled={true} className="">
                  {product.available_now_nl ? product.available_now_nl : <i className="fas fa-times"></i>}
                </Link>
                &nbsp;-&nbsp;
                <Link to={`/producten`} onClick={e => this.onQuickEdit(e, product, TYPE_QUICKEDIT_AVAILABLE)} disabled={true} className="">
                  {product.available_later_nl  ? product.available_later_nl : <i className="fas fa-times"></i>}
                </Link>
              </p>
            </div>
            <div className="col-2 pt-2">
              <div className="row">
                <div className="col-7">
                  <h4>
                    <Link to={`/producten`} onClick={e => this.onQuickEdit(e, product, TYPE_QUICKEDIT_PRICE)} disabled={true} className="">
                      &euro;{Number(product.price * (1 + +product.rate/100)).toFixed(2)}
                    </Link>
                  </h4>
                </div>
                <div className="col-5">
                  <small><i class="fas fa-truck"></i> {product.wholesale_price
                    ? `€${Number(product.wholesale_price).toFixed(2)}`
                    : <i className="fas fa-times"></i>}</small>
                </div>
              </div>

              <p className="mb-0">&euro;{Number(product.price).toFixed(2)} + <small className="">{Number(+product.rate).toFixed(0)}% btw</small></p>
              <p className="">
              </p>
            </div>
            <div className="col-3 pt-2">
              <div className="row">
                <div className="col-12">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      {+product.active === 1 ? (
                        <button className={`btn btn-outline-success`} title="online" disabled={changing ? 'disabled' : ''} onClick={e => this.onSetField(e, product, 'active', 0)}>
                          <i className={`fas fa-eye`}></i>
                        </button>
                      ) : (
                        <button className={`btn btn-outline-danger`} title="offline" disabled={changing ? 'disabled' : ''} onClick={e => this.onSetField(e, product, 'active', 1)}>
                          <i className={`fas fa-eye-slash`}></i>
                        </button>
                      )}
                    </li>

                    <li className="list-inline-item">
                      {+product.available_for_order === 1 ? (
                        <button className={`btn btn-outline-success`} title="available for order" disabled={disabled ? 'disabled' : ''} onClick={e => this.onSetField(e, product, 'available_for_order', 0)}>
                          <i className={`fas fa-check`}></i>
                        </button>
                      ) : (
                        <button className={`btn btn-outline-danger`} title="not available for order" disabled={disabled ? 'disabled' : ''} onClick={e => this.onSetField(e, product, 'available_for_order', 1)}>
                          <i className={`fas fa-times`}></i>
                        </button>
                      )}
                    </li>

                    <li className="list-inline-item">
                      {+product.archived === 0 ? (
                        <button className={`btn btn-outline-danger`} title="archived" disabled={disabled ? 'disabled' : ''} onClick={e => this.onSetField(e, product, 'archived', 1)}>
                          <i className={`fas fa-trash`}></i>
                        </button>
                      ) : null}
                    </li>

                    <li className="list-inline-item">
                      <Link to={`/producten?id=${product.id_product}`} onClick={e => this.onSelectProduct(e, product.id_product)} className="btn btn-primary" disabled={disabled}>
                        <i className="fas fa-edit"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-12">
                  <ul className="list-inline">
                    <li className="list-inline-item">
                      <button type="button" className="btn btn-outline-primary" onClick={e => this.onPrintPriceLabel(e, product)} disabled={disabled ? 'disabled' : ''}>
                        <i className="fas fa-print"></i> <i className="fas fa-tags"></i>
                      </button>
                    </li>
                    <li className="list-inline-item">
                      <button type="button" className="btn btn-outline-primary" onClick={e => this.onPrintSmallLabel(e, product)} disabled={disabled ? 'disabled' : ''}>
                        <i className="fas fa-print"></i> <i className="fas fa-dollar-sign"></i>
                      </button>
                    </li>
                    <li className="list-inline-item">
                      <button type="button" className="btn btn-outline-primary" onClick={e => this.onPrintJewelleryLabel(e, product)} disabled={disabled ? 'disabled' : ''}>
                        <i className="fas fa-print"></i> <i className="fas fa-ring"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  renderProducts() {
    const { products } = this.state

    if (products.length === 0) {
      return (
        <div className="col-12 mb-2">
          <div className="alert alert-warning" role="alert">
            <i className="fas fa-exclamation-circle"></i> Geen producten gevonden. Gelieve een andere zoekopdracht te proberen.
          </div>
        </div>
      )
    }

    return (
      <>
        {products ? products.map(product => (

            <div className="col-12 mb-2" key={`product-${product.id_product}-${product.id_combination}`}>
              <div className="card">
                <div className="card-body p-0 px-1">

                  {this.renderProduct(product)}

                </div>
              </div>
            </div>

        )) : ''}
      </>
    )
  }

  renderSearchForm() {
    const { manufacturers, searching, disabled } = this.state

    return (
      <form className="" onSubmit={this.onSearch}>
        <div className="row">
          <div className="form-group mx-sm-3 mb-2 w-100">
            <input type="text" className="form-control" placeholder="Vrij zoeken" name="query" value={this.state.query.value} onChange={this.onChange} />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="row">

              <div className="col-md-4 mb-2">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="EAN13" name="ean13" onChange={this.onChange} />
                </div>
              </div>

              <div className="col-md-4 mb-2">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="ID" name="id_product" onChange={this.onChange} />
                </div>
              </div>

              <div className="col-md-4 mb-2">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Referentie" name="reference" onChange={this.onChange} />
                </div>
              </div>

            </div>
          </div>
          <div className="col-md-6">
            <div className="row">

              <div className="col-md-4">
                <div className="form-group">
                  <select className="form-control" name="id_manufacturer" onChange={this.onChange}>
                    <option value="">Kies een merk</option>
                    {manufacturers ? manufacturers.map(m => (
                      <option value={m.id_manufacturer} key={`select-manufacturer-${m.id_manufacturer}`}>{m.name}</option>
                    )) : null}
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <div className="form-group">
                  <select className="form-control" name="id_category" onChange={this.onChange}>
                    <option value="">Archived?</option>
                    <option value="1">enkel archived</option>
                    <option value="0">zonder archived</option>
                    <option value="-1">alles</option>
                  </select>
                </div>
              </div>

              <div className="col-md-4">
                <button type="submit" className="btn btn-primary mb-2 ml-auto" disabled={disabled ? 'disabled' : ''}>
                  <i className={`fas ${searching ? 'fa-spinner fa-spi' : 'fa-search'}`}></i> Zoeken
                </button>

                <button type="button" className="btn btn-outline-danger mb-2 ml-2 mr-md-3" onClick={this.onReset} disabled={disabled ? 'disabled' : ''}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>

          </div>
        </div>
      </form>
    )
  }

  render() {
    const { products, loading } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Producten zoeken`} loading={loading}>
              <>
                {loading ? null : this.renderSearchForm()}

                <div className="products row mt-4">
                  {Array.isArray(products) ? this.renderProducts() : null}
                </div>
              </>
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default ProductSearchWidget
