import React from 'react'
import { Link } from 'gatsby'

import { PageLayout } from '../..'

class MarketingDashboardPage extends React.Component {
  render() {

    return (
      <PageLayout>
        <div className="row">
          <div className="col-4">
            <div className="card">
              <div className="card-header">
                <h6>Tools</h6>
              </div>
              <div className="list-group list-group-flush">
                <Link to="/marketing/picker" className="list-group-item list-group-item-action">
                  <i className="fas fa-trophy"></i> Wedstrijd picker
                </Link>
              </div>
            </div>
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default MarketingDashboardPage
