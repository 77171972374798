import React from 'react'

import './picker.scss'

const showSocialIcon = false

class CampaignPickerResultView extends React.Component {
  constructor(props) {
    super(props)

    const items = props.names ? props.names : []

    this.state = {
      running: false,
      winner: items.length > 4
        ? items[4].value
        : items.length > 0
          ? items[0].value
          : null,
      names: items,
      h: items.length * 65,
      runtime: items.length > 100
        ? 10
        : items.length > 50
          ? 6
          : 3
    }
  }

  onStartPicking = (e) => {
    e.preventDefault()
    this.setState({ running: true })
  }

  onCancel = (e) => {
    e.preventDefault()
    this.props.onStop()
  }

  render() {
    const { running, winner, names, h, runtime } = this.state

    const oStyle = running
      ? { marginTop: `-${h}px`, transition: `transform ${runtime}s ease-out`, transform: `translateY(${h}px)` }
      : { marginTop: `-${h}px` }

    return (
      <div className="container-fluid">

        <div className="row mb-2">
          <div className="col-4 offset-4 text-center=">
            <img src="/logo.jpg" alt="Up North" className="img-fluid" />
          </div>
        </div>

          <div className="row">
            <div className="col-12">
              <div className="float-left">
                <h1 className="archivo">{names.length} deelnemers</h1>
              </div>
              <div className="float-right">
                <button className="btn btn-dark btn-lg" onClick={this.onStartPicking} disabled={names.length > 0 ? '' : 'disabled'}>GO</button>
                <button className="btn btn-dark btn-lg ml-1" onClick={this.onCancel}>x</button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 picker-container">

                <div className={`list-names picker ${running ? 'running' : ''}`}>
                  <ul className="list-unstyled" style={oStyle}>
                  {names.map((name, idx) => (
                    <li key={`name-${idx}`} className={`w-100 text-center archivo py-2 ${name.value === winner ? 'winner' : ''}`}>
                      <h2>{showSocialIcon ? name.type === 'fb' ? (<i className="fab fa-facebook-square"></i>) : (<i className="fab fa-instagram"></i>) : ''} {name.value} {name.value === winner ? (<span className="ml-1 badge badge-primary">WINNER</span>) : ''}</h2>
                    </li>
                  ))}
                  </ul>
                </div>

            </div>
          </div>

      </div>
    )
  }
}

export default CampaignPickerResultView
