import React from 'react'
import { navigate } from 'gatsby'

import AppContext from '../../../context/App/Context'

class ProductSearchForm extends React.Component {
  context = null

  state = {
    query: '',
    loading: false
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSearch = (e) => {
    e.preventDefault()

    navigate(`/producten?s=${this.state.query}`)
  }

  render() {
    const { className } = this.props
    const { query } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <form className={className} onSubmit={this.onSearch}>
              <div className="input-group my-1 w-100">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    <i className="fas fa-search"></i>
                  </span>
                </div>
                <input type="text" name="query" value={query} className="form-control" placeholder="Zoek op product" onChange={this.onChange} />
                <div className="input-group-append">
                  <button className="btn btn-light" type="button" id="button-addon2">Zoeken</button>
                </div>
              </div>
            </form>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default ProductSearchForm
