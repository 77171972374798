import { callAPI } from './api'

const getDailyProductSales = (context) =>
  callAPI(
    context,
    `/store/daily-sales`,
    'GET'
  )

export default {
  getDailyProductSales
}
