import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import queryString from 'query-string'

import AppContext from '../../../context/App/Context'
import { PageLayout, BrandNavbar, BrandListWidget, BrandDetailsWidget } from '../..'

class BrandListPage extends React.Component {
  state = {
    selected: null
  }

  componentDidMount() {
    if (window && window.location && window.location.search) {
      const params = queryString.parse(window.location.search)
      if (params && params.id) {
        this.setState({ selected: +params.id })
      }
    }
  }

  setSelected = (id) => {
    this.setState({ selected: id ? +id : null })
  }

  render() {
    const { selected } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          return (
            <PageLayout>
              <div className="row mb-2">
                <div className="col-12">
                  <BrandNavbar setSelected={this.setSelected} />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {selected
                    ? <BrandDetailsWidget context={context} id={selected} />
                    : <BrandListWidget context={context} setSelected={this.setSelected} />}
                </div>
              </div>
            </PageLayout>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

BrandListPage.propTypes = {}

export default BrandListPage
