import React from 'react'
import PropTypes from 'prop-types'

import { NotificationToast } from '../..'

import './notificationlist.scss'

class NotificationList extends React.Component {
  render() {
    const { context } = this.props

    return (
      <div className="notifications notifications-container" aria-live="polite" aria-atomic="true">
        {context.notifications ? context.notifications.map(({ title, icon, content }, idx) => (
          <NotificationToast title={title} icon={icon} content={content} key={idx} />
        )) : null}
      </div>
    )
  }
}

NotificationList.propTypes = {
  context: PropTypes.object.isRequired
}

export default NotificationList
