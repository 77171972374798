import React from 'react'

import { Widget } from '../..'
import productAPI from '../../../services/product'
import AppContext from '../../../context/App/Context'

import ProductEditWidgetLocale from './ProductEditWidgetLocale'
import ProductEditWidgetDetails from './ProductEditWidgetDetails'

const TAB_DETAILS = 0
const TAB_ENGLISH = 1
const TAB_DUTCH = 2

const tabs = [
  { id: TAB_DETAILS, title: 'Algemeen' },
  { id: TAB_ENGLISH, title: 'Engels' },
  { id: TAB_DUTCH, title: 'Nederlands' },
]

class ProductEditWidget extends React.Component {
  context = null

  state = {
    // data
    activeTab: 0,
    product: null,
    stock: null,
    tax_rules: null,
    manufacturers: null,
    // flags
    loading: false,
    disabled: false
  }

  componentDidMount() {
    const { id } = this.props

    this.setState({ loading: true }, () => {
      productAPI.getProduct(this.context, id, 0).then(result => {
        if (result && result.product) {
          const taxrule = result.tax_rules.find(t => +t.id_tax_rules_group === +result.product.id_tax_rules_group)
          result.product.sellprice = Number(+result.product.price * (1 + taxrule.rate/100)).toFixed(2)

          this.setState({
            loading: false,
            product: result.product,
            stock: result.stock,
            tax_rules: result.tax_rules,
            manufacturers: result.manufacturers
          })
        } else {
          this.setState({
            loading: false,
            product: null,
            stock: null,
            tax_rules: null,
            manufacturers: null
          })
        }
      })
    })
  }

  onChangeTab = (e) => {
    e.preventDefault()
    this.setState({ activeTab: +e.target.getAttribute('data-tab')})
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onProductChange = (e) => {
    if (e.target.name === 'sellprice' || e.target.name === 'price') {
      e.target.value = e.target.value.split(',').join('.')
    }

    const p = this.state.product

    p[e.target.name] = e.target.value
    const tax = this.state.tax_rules.find(i => +i.id_tax_rules_group === +p.id_tax_rules_group)

    if (e.target.name === 'id_tax_rules_group') {
      p.price = +p.sellprice / (1 + (+tax.rate/100))
    } else if (e.target.name === 'sellprice') {
      p.price = +p.sellprice / (1 + (+tax.rate/100))
    } else if (e.target.name === 'price') {
      p.sellprice = +p.price * (1 + (+tax.rate/100))
    }

    this.setState({ product: p })
  }

  onProductChecked = (e) => {
    const p = this.state.product
    p[e.target.name] = e.target.checked
    this.setState({ product: p })
  }

  onStockChange = (e) => {
    const s = this.state.stock
    s[e.target.name] = e.target.value
    this.setState({ stock: s })
  }

  onClose = (e) => {
    if (this.props.onCancel) this.props.onCancel()
  }

  onSaveProduct = (e, close) => {
    if (e) e.preventDefault()
    const { id } = this.props
    const { product, stock } = this.state

    this.setState({ loading: true, disabled: true }, () => {
      productAPI.updateProduct(this.context, id, 0, product, stock).then(result => {
        this.setState({
          product: result && result.product ? result.product : product,
          stock: result && result.stock ? result.stock : stock,
          loading: false,
          disabled: false
        }, () => {
          if (close) this.props.onCancel()
        })
      })
    })
  }

  widgetTitle = () => {
    const { activeTab } = this.state

    return (
      <>
        <ul className="nav nav-tabs mb-0" role="tablist">
          {tabs.map(tab => (
            <li className="nav-item" key={`nav-item-${tab.id}`}>
              <a className={`nav-link ${tab.id === activeTab ? 'active' : ''}`} href="/" role="tab" data-tab={tab.id} onClick={this.onChangeTab} title={tab.title}>
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
      </>
    )
  }

  widgetFooter = (disabled) => {
    return (
      <div className="row">
        <div className="col-4">
          <button className="btn btn-secondary btn-block" type="button" disabled={disabled} onClick={e => this.props.onCancel()}>
            <i className="fas fa-times-circle"></i> annuleren
          </button>
        </div>
        <div className="col-4">
          <button className="btn btn-primary btn-block" type="button" disabled={disabled} onClick={e => this.onSaveProduct(e, false)}>
            <i className="fas fa-check-circle"></i> bewaren
          </button>
        </div>
        <div className="col-4">
          <button className="btn btn-primary btn-block" type="button" disabled={disabled} onClick={e => this.onSaveProduct(e, true)}>
            <i className="fas fa-sign-out-alt"></i> bewaren en sluiten
          </button>
        </div>
      </div>
    )
  }

  widgetBody = (context) => {
    const { activeTab, disabled, product, stock, tax_rules, manufacturers } = this.state

    if (product) {
      return (
        <>
          <ProductEditWidgetDetails
            context={context}
            product={product}
            stock={stock}
            tax_rules={tax_rules}
            manufacturers={manufacturers}
            onProductChange={this.onProductChange}
            onProductChecked={this.onProductChecked}
            onStockChange={this.onStockChange}
            disabled={disabled}
            visible={activeTab === TAB_DETAILS} />
          <ProductEditWidgetLocale
            context={context}
            product={product}
            lang={{ id: 1, title: 'EN' }}
            onProductChange={this.onProductChange}
            disabled={disabled}
            visible={activeTab === TAB_ENGLISH} />
          <ProductEditWidgetLocale
            context={context}
            product={product}
            lang={{ id: 4, title: 'NL' }}
            onProductChange={this.onProductChange}
            disabled={disabled}
            visible={activeTab === TAB_DUTCH} />
        </>
      )
    }

    return <div></div>
  }

  render() {
    const { loading, disabled } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context
          return (
            <Widget display={2} title={this.widgetTitle()} footer={this.widgetFooter(disabled)} loading={loading} onClose={this.onClose}>
              {this.widgetBody(context)}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default ProductEditWidget
