import React from 'react'

import { Widget } from '../..'
import { getSmallLabelUrl } from '../../../services/printer'

class PrinterSmallLabelWidget extends React.Component {
  state = {
    price: null
  }

  onChange = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onPrint = (e) => {
    const { price } = this.state

    e.preventDefault()
    window.open(getSmallLabelUrl(price), "_blank")
    return false
  }

  footer() {
    return (<p>print via custom size: width=650mm height=450mm margins=0mm orientation=landscape scale=100%</p>)
  }

  render() {
    return (
      <Widget title={`Mini Label`} footer={this.footer()}>
        <form className="">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">€</span>
            </div>
            <input type="text" className="form-control" name="price" placeholder="prijs" onChange={(e) => this.onChange(e)} />
          </div>
          <button className="btn btn-primary btn-block" type="button" onClick={(e) => this.onPrint(e)}>
            <i className="fas fa-print"></i> print
          </button>
        </form>
      </Widget>
    )
  }
}

export default PrinterSmallLabelWidget
