import React from 'react'
import PropTypes from 'prop-types'

class TextAreaEditor extends React.Component {
  render() {
    const { name, content, disabled, onChange } = this.props

    const CKEditor = require('@ckeditor/ckeditor5-react')
    const ClassicEditor = require('@ckeditor/ckeditor5-build-classic')

    return (
      <CKEditor
        editor={ ClassicEditor }
        data={content}
        disabled={disabled}
        // onInit={editor => { console.log( 'Editor is ready to use!', editor ) }}
        onChange={(event, editor) => {
          const data = editor.getData()
          if (onChange) {
            onChange(name, data)
          }
        }}
        // onBlur={(event, editor) => {
        //   console.log('Blur.', editor)
        // }}
        // onFocus={(event, editor) => {
        //   console.log('Focus.', editor)
        // }}
      />
    )
  }
}

TextAreaEditor.propTypes = {
  name: PropTypes.string,
  content: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
}

export default TextAreaEditor
