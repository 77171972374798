const req3 = require.context('.', true, /\.\/[^/]+\/index\.js$/)
req3.keys().forEach((key) => {
  const componentName = key.replace(/^.+\/([^/]+)\/index\.js/, '$1').replace('/', '')
  module.exports[componentName] = req3(key).default
})

const req1 = require.context('.', true, /\.\/[^/]+\/[^/]+\/index\.js$/)
req1.keys().forEach((key) => {
  const componentName = key.replace(/^.+\/([^/]+\/[^/]+)\/index\.js/, '$1').replace('/', '').replace('Atom', '')
  module.exports[componentName] = req1(key).default
})

const req2 = require.context('.', true, /\.\/[^/]+\/[^/]+\/[^/]+\/index\.js$/)
req2.keys().forEach((key) => {
  const componentName = key.replace(/^.+\/([^/]+\/[^/]+\/[^/]+)\/index\.js/, '$1').replace('/', '').replace('/', '')
  module.exports[componentName] = req2(key).default
})
