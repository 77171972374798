import React from 'react'

import { PageLayout, DailyWebOrderStatisticsChartWidget, DailyWebOrderStatisticsTop10Widget } from '../..'
import { USERS_WITHOUT_ACCESS } from '../../../constants/access'

class DailyWebOrderStatisticsPage extends React.Component {
  render() {
    const hasAccess = (ctx) => {
      if (USERS_WITHOUT_ACCESS.includes(+ctx.user.id_employee)) return false
      return true
    }

    return (
      <PageLayout hasAccess={hasAccess}>
        <div className="row">
          <div className="col-12 mb-2">
            <DailyWebOrderStatisticsChartWidget />
          </div>
          <div className="col-12 mb-2">
            <DailyWebOrderStatisticsTop10Widget />
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default DailyWebOrderStatisticsPage
