import React from 'react'
import PropTypes from 'prop-types'

import productAPI from '../../../../services/product'

const OUTOFSTOCK_ALLOW = '1'
const OUTOFSTOCK_DISALLOW = '0'
const OUTOFSTOCK_DEFAULT = '2'

class ProductSearchWidgetQuickEditPrice extends React.Component {
  state = {
    price: '',
    sellprice: '',
    wholesale_price: '',
    id_tax_rules_group: '',
    rate: '',
    ean13: '',
    reference: '',
    out_of_stock: OUTOFSTOCK_DEFAULT,
    syncing: false
  }

  constructor(props) {
    super(props)

    this.state.price = props.product.price
    this.state.rate = props.product.rate
    this.state.sellprice = Number(+props.product.price * (1 + +props.product.rate/100)).toFixed(2)
    this.state.wholesale_price = props.product.wholesale_price
    this.state.id_tax_rules_group = props.product.id_tax_rules_group
    this.state.out_of_stock = props.product.out_of_stock
  }

  onChange = (e) => {
    const { taxrules } = this.props
    let { rate, id_tax_rules_group, price, sellprice, wholesale_price, out_of_stock } = this.state

    if (e.target.name === 'id_tax_rules_group') {
      id_tax_rules_group = e.target.value
      const tax = taxrules.find(t => +t.id_tax_rules_group === +id_tax_rules_group)
      rate = tax.rate
      price = +sellprice / (1 + +rate/100)
    } else if (e.target.name === 'price') {
      price = e.target.value.split(',').join('.')
      sellprice = +price * (1 + +rate/100)
    } else if (e.target.name === 'sellprice') {
      sellprice = e.target.value.split(',').join('.')
      price = +sellprice / (1 + +rate/100)
    } else if (e.target.name === 'wholesale_price') {
      wholesale_price = e.target.value.split(',').join('.')
    } else if (e.target.name === 'out_of_stock') {
      out_of_stock = e.target.value
    }

    this.setState({
      rate: rate,
      id_tax_rules_group: id_tax_rules_group,
      price: price,
      sellprice: sellprice,
      wholesale_price: wholesale_price,
      out_of_stock: out_of_stock
    })
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { price, id_tax_rules_group, wholesale_price, out_of_stock } = this.state
    const { disabled, toggleDisabled, onSuccess, context, product } = this.props
    if (!disabled) {
      this.setState({ syncing: true }, () => {
        toggleDisabled(true)

        productAPI.updateProductField(context, product.id_product, product.id_combination, 'price', price).then(() => {
          productAPI.updateProductField(context, product.id_product, product.id_combination, 'id_tax_rules_group', id_tax_rules_group).then(() => {
            productAPI.updateProductField(context, product.id_product, product.id_combination, 'wholesale_price', wholesale_price).then(() => {
              productAPI.updateProductField(context, product.id_product, product.id_combination, 'out_of_stock', out_of_stock).then(result => {
                this.setState({ syncing: false }, () => {
                  toggleDisabled(false)
                  if (result && result.product) {
                    onSuccess(result.product)
                  }
                })
              })
            })
          })
        })
      })
    }
  }

  render() {
    const { price, sellprice, wholesale_price, id_tax_rules_group, syncing, out_of_stock } = this.state
    const { onCancel, disabled, taxrules } = this.props

    return (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="row mb-1">
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Verkoopprijs (incl btw)</span>
              </div>
              <input autoFocus type="text" className="form-control" placeholder="Verkoopprijs" name="sellprice" value={sellprice} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Verkoopprijs (excl btw)</span>
              </div>
              <input type="text" className="form-control" placeholder="Engels" name="price" value={price} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">BTW tarief</span>
              </div>
              <select className="form-control" name="id_tax_rules_group" value={id_tax_rules_group} onChange={this.onChange} disabled={disabled}>
                {taxrules.map(t => (
                  <option value={t.id_tax_rules_group} key={`taxrule-${t.id_tax_rules_group}`}>{t.name_nl}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-barcode mr-1"></i>Aankoopprijs</span>
              </div>
              <input type="text" className="form-control" placeholder="Aankoopprijs" name="wholesale_price" value={wholesale_price} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
          <div className="col-6">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-barcode mr-1"></i>Wanneer niet in stock</span>
              </div>
              <select name="out_of_stock" id="fout_of_order" value={out_of_stock} className="custom-select" disabled={disabled} onChange={this.onChange}>
                <option value={OUTOFSTOCK_DISALLOW}>Bestellingen niet toestaan</option>
                <option value={OUTOFSTOCK_ALLOW}>Bestellingen toestaan</option>
                <option value={OUTOFSTOCK_DEFAULT}>Gebruik standaard gedrag</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-secondary" onClick={onCancel} disabled={disabled}>
              <i className="fas fa-times"></i> annuleren
            </button>
            <button type="submit" className="btn btn-primary ml-1" disabled={disabled}>
              <i className={`fas ${syncing ? 'fa-spinner fa-spin' : 'fa-check'}`}></i> bewaren
            </button>
          </div>
        </div>
      </form>
    )
  }
}

ProductSearchWidgetQuickEditPrice.propTypes = {
  product: PropTypes.object,
  context: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  toggleDisabled: PropTypes.func
}

export default ProductSearchWidgetQuickEditPrice
