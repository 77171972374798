import { callAPI } from './api'

export function getList(context) {
  return callAPI(context, '/orders', 'GET').then(result => {
    return result && result.orders ? result : null
  })
}

export function get(context, id) {
  return callAPI(context, `/orders/${id}`, 'GET').then(result => {
    return result && result.order ? result.order : null
  })
}

export function getWebOrders(context, carrier, status) {
  return callAPI(context, `/orders/web`, 'GET', { carrier: carrier, status: status })
}

export function getWebOrderTotals(context) {
  return callAPI(context, `/orders/web/total`, 'GET')
}

export default {
  getList,
  get,
  getWebOrders,
  getWebOrderTotals
}
