import React from 'react'

import sessionAPI from '../../../services/session'
import AppContext from '../../../context/App/Context'

import './loginform.scss'

class LoginForm extends React.Component {
  context = null

  state = {
    email: null,
    password: null,
    loading: false,
    error: false
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()

    if (this.state.loading) return false

    const { email, password } = this.state
    this.setState({ loading: true, error: false }, () => {
      sessionAPI.login(email, password).then(result => {
        if (result && result.user && result.user.token) {
          this.context.onLogin(result.user, result.user.token)
          this.setState({ loading: false, error: false })
        } else {
          this.setState({ loading: false, error: true })
        }
      })
    })
  }

  render() {
    const { loading } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <form className="form-signin" onSubmit={this.onSubmit}>
              <label htmlFor="inputEmail">Email address</label>
              <input type="email" id="inputEmail" name="email" className="form-control" placeholder="Email address" onChange={this.onChange} />
              <label htmlFor="inputPassword">Password</label>
              <input type="password" id="inputPassword" name="password" className="form-control" placeholder="Password" onChange={this.onChange} />
              <button className="btn btn-lg btn-primary btn-block" type="submit" disabled={loading ? 'disabled': ''}>
                Sign in
              </button>
            </form>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default LoginForm
