import React from 'react'

import { PageLayout, PrinterPriceLabelWidget, PrinterSmallLabelWidget, PrinterJewelleryLabelWidget } from '../..'

class PrinterPage extends React.Component {
  render() {

    return (
      <PageLayout>
        <div className="row">
          <div className="col-md-6">
            <PrinterSmallLabelWidget />
            <br />
            <PrinterJewelleryLabelWidget />
          </div>
          <div className="col-md-6">
            <PrinterPriceLabelWidget />
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default PrinterPage
