import React from 'react'

import { Widget, PriceLabel } from '../..'
import AppContext from '../../../context/App/Context'
import { getTopWebOrderStatistics } from '../../../services/reporting'

class DailyWebOrderStatisticsTop10Widget extends React.Component {
  context = null

  state = {
    loading: false,
    overall: null,
    overall2: null
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      Promise.all([
        getTopWebOrderStatistics(this.context)
      ]).then(data => {
        this.setState({
          overall: data && data[0] && data[0][0] ? data[0][0] : null,
          overall2: data && data[0] && data[0][1] ? data[0][1] : null,
          loading: false
        })
      })
    })
  }

  render() {
    const { overall, overall2, loading } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Top dagen`} loading={loading} display={3}>
              {loading ? <></> : (
                <div className="row m-1">
                  <div className="col-md-6">
                    {overall ? (
                      <table className="table table-bordered my-2">
                        <thead>
                          <tr>
                            <th>Datum</th>
                            <th className="bg-dark text-white">Aantal <i className="fas fa-chevron-down"></i></th>
                            <th>Bedrag</th>
                          </tr>
                        </thead>
                        <tbody>
                          {overall.map(i => (
                            <tr key={`DailyWebOrderStatisticsTop10Widget-${i.year}-${i.month}-${i.day}`}>
                              <td>{i.year}-{i.month}-{i.day}</td>
                              <td>{i.num_orders}</td>
                              <td>&euro;{Number(i.total_orders).toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    {overall2 ? (
                      <table className="table table-bordered my-2">
                        <thead>
                          <tr>
                            <th>Datum</th>
                            <th>Aantal</th>
                            <th className="bg-dark text-white">Bedrag <i class="fas fa-chevron-down"></i></th>
                          </tr>
                        </thead>
                        <tbody>
                          {overall2.map(i => (
                            <tr key={`DailyWebOrderStatisticsTop10Widget2-${i.year}-${i.month}-${i.day}`}>
                              <td>{i.year}-{i.month}-{i.day}</td>
                              <td>{i.num_orders}</td>
                              <td>&euro;{Number(i.total_orders).toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : null}
                  </div>
                </div>
              )}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default DailyWebOrderStatisticsTop10Widget
