import React from 'react'
import PropTypes from 'prop-types'
import DateFormat from 'dateformat'

import { Widget, ProductImage } from '../..'
import AppContext from '../../../context/App/Context'
import giftregistryAPI from '../../../services/giftregistry'
import productsearchAPI from '../../../services/productsearch'
import { formatPrice } from '../../../utils/formatting'

const tabs = [
  { id: 0, title: 'Details' },
  { id: 1, title: 'Producten' },
  { id: 2, title: 'Bestellingen' },
  { id: 3, title: 'Historiek' }
]

const priorities = [
  { id: 0, title: 'Top', badge: 'badge-danger' },
  { id: 1, title: 'Heel belangrijk', badge: 'badge-warning' },
  { id: 2, title: 'Belangrijk', badge: 'badge-warning' },
  { id: 3, title: 'Medium', badge: 'badge-secondary' },
  { id: 4, title: 'Laag', badge: 'badge-light' }
]

class GiftRegistryProducts extends React.Component {
  render() {
    const { items, disabled, onDeleteItem, deleteItem } = this.props

    return (
      <>
        <div className="row">
          <div className="col-12">
            <table className="table">
              <thead>
                <tr>
                  <th>Aantal</th>
                  <th>Foto</th>
                  <th>Product</th>
                  <th>Verkocht</th>
                  <th>Prioriteit</th>
                  <th>Acties</th>
                </tr>
              </thead>
              <tbody>
                {items ? items.filter(i => +i.quantity > 0).map(item => {
                  const priority = priorities.find(i => +i.id === +item.priority)
                  return (
                    <tr key={`wishlist-product-${item.id_wishlist_product}`}>
                      <td>
                        <h5><span className={`badge ${item.quantity_left > 0 ? 'badge-primary' : 'badge-light'}`}>{item.quantity}</span></h5>
                      </td>
                      <td style={{ width: '75px' }}>
                        <ProductImage product={item} className="" />
                      </td>
                      <td>{item.name}{item.combination ? `: ${item.combination}` : ''}</td>
                      <td>
                        <h5><span className={`badge ${item.quantity_sold > 0 ? 'badge-success' : 'badge-light'}`}>{item.quantity_sold}</span></h5>
                      </td>
                      <td className="text-center">
                        <h5><span className={`badge ${priority.badge}`}>{priority.title}</span></h5>
                      </td>
                      <td>
                        <button className="btn btn-sm btn-danger" disabled={disabled} onClick={e => deleteItem(e, item)}>
                          <i className={`fas ${+onDeleteItem === +item.id_wishlist_product ? 'fa-spinner fa-spin' : 'fa-trash'}`}></i>
                        </button>
                      </td>
                    </tr>
                  )
                }) : null}
              </tbody>
            </table>
          </div>
        </div>
      </>
    )
  }
}

class GiftRegistryDetailsWidget extends React.Component {
  context = null

  state = {
    activeTab: 0,
    loading: false,
    disabled: false,
    name: null,
    item: null,
    items: null,
    orders: null,
    carriers: null,
    orderstates: null,
    history: null,
    onDeleteItem: null,
    query: '',
    searching: false,
    showSearchResults: false,
    searchResults: null,
    onAddToWishList: false
  }

  componentDidMount() {
    const { id } = this.props

    this.setState({ loading: true }, () => {
      giftregistryAPI.get(this.context, id).then(({ giftregistry, items, orders, carriers, orderstates, history }) => {
        this.setState({
          name: giftregistry.name,
          item: giftregistry,
          items: items,
          orders: orders,
          carriers: carriers,
          orderstates: orderstates,
          history: history,
          loading: false
        })
      })
    })
  }

  onChangeTab = (e) => {
    e.preventDefault()
    this.setState({ activeTab: +e.target.getAttribute('data-tab') })
  }

  onChangeItem = (e) => {
    const { item } = this.state
    item[e.target.name] = e.target.value
    this.setState({ item: item })
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value })

  onDeleteItem = (e, item) => {
    e.preventDefault()

    if (!this.state.disabled) {
      this.setState({ disabled: true, onDeleteItem: +item.id_wishlist_product }, () => {
        giftregistryAPI.removeListItem(this.context, item.id_wishlist, item.id_wishlist_product).then(({ items, history }) => {
          this.setState({ items: items, history: history, disabled: false, onDeleteItem: null })
        })
      })
    }
  }

  onSendOrderConfMailToList = (e) => {
    const { item } = this.state

    e.preventDefault()

    const id_order = e.target.getAttribute('data-order')
    const id_order_customer = e.target.getAttribute('data-customer')

    giftregistryAPI.sendOrderConfMailToList(this.context, id_order, item.id_wishlist, id_order_customer, item.id_customer).then(result => {
      this.context.addNotification('Gelukt', 'fas fa-check-circle', `Email is verzonden.`)
    })
  }

  onSearch = (e) => {
    e.preventDefault()

    this.setState({ disabled: true, searching: true, showSearchResults: true, searchResults: null }, () => {
      productsearchAPI.search(this.context, this.state.query).then(({ products }) => {
        this.setState({ disabled: false, searching: false, searchResults: products })
      })
    })
  }

  onCloseSearch = (e) => {
    e.preventDefault()
    this.setState({ showSearchResults: false, searchResults: null })
  }

  onAddToWishList = (e, idx, item) => {
    e.preventDefault()
    const { id } = this.props
    this.setState({ onAddToWishList: +idx, disabled: true }, () => {
      const id_product_attribute = item.id_combination ? item.id_combination : 0
      giftregistryAPI.addListItem(this.context, id, item.id_product, id_product_attribute, 1).then(result => {
        if (result && result.items) {
          this.setState({ onAddToWishList: null, disabled: false, items: result.items })
        } else {
          this.setState({ onAddToWishList: null, disabled: false })
        }
      })
    })
  }

  widgetTitle() {
    const { activeTab, name, items, orders, history } = this.state

    return (
      <>
        <ul className="nav nav-tabs mb-0" role="tablist">
          {tabs.map(tab => (
            <li className="nav-item" key={`nav-item-${tab.id}`}>
              <a className={`nav-link ${tab.id === activeTab ? 'active' : ''}`} href="/" role="tab" data-tab={tab.id} onClick={this.onChangeTab} title={tab.title}>
                {tab.title}
                {tab.id === 1 ? <span className="badge badge-info ml-1">{items ? items.length : 0}</span> : null}
                {tab.id === 2 ? <span className="badge badge-info ml-1">{orders ? orders.length : 0}</span> : null}
                {tab.id === 3 ? <span className="badge badge-secondary ml-1">{history ? history.length : 0}</span> : null}
              </a>
            </li>
          ))}
        </ul>
      </>
    )
  }

  render() {
    const { onClose } = this.props
    const { loading, item } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={this.widgetTitle()} loading={loading} display={2} onClose={onClose ? onClose : null} >
              {loading || !item ? <></> : this.renderBody()}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }

  renderBody() {
    const { activeTab } = this.state

    if (activeTab === 0) {
      return this.renderDetails()
    } else if (activeTab === 1) {
      return this.renderProducts()
    } else if (activeTab === 2) {
      return this.renderOrders()
    } else if (activeTab === 3) {
      return this.renderHistory()
    }
  }

  renderHistoryEventName(name) {
    if (name === 'item-added') {
      return <span className="badge badge-success"><i className="fas fa-plus"></i> Item toegevoegd</span>
    } else if (name === 'item-deleted') {
      return <span className="badge badge-danger"><i className="fas fa-minus"></i> Item verwijderd</span>
    } else if (name === 'item-updated') {
      return <span className="badge badge-primary"><i className="fas fa-edit"></i> Item aangepast</span>
    }
  }

  renderHistory() {
    const { history, items } = this.state

    return (
      <table className="table">
        <thead>
          <tr>
            <th>Event</th>
            <th>Datum</th>
            <th>Product</th>
            <th>Data</th>
            <th>Door</th>
          </tr>
        </thead>
        <tbody>
          {history.map(i => {
            const date_add = new Date(i.date_add)
            const product = items.find(p => +i.id_wishlist_product === +p.id_wishlist_product)
            if (!product) return null

            return (
              <tr key={`history-${i.id_event}`}>
                <td>{this.renderHistoryEventName(i.event_name)}</td>
                <td>{DateFormat(date_add, "dd/mm/yyyy HH:MM:ss")}</td>
                <td>{product.name}{product.combination ? ` # ${product.combination}` : ''}</td>
                <td></td>
                <td>
                  {i.customer_email ? (
                    <span className="badge badge-primary"><i className="fas fa-user"></i> {i.customer_email}</span>
                  ) : (
                    <span className="badge badge-danger"><i className="fas fa-user-cog"></i> {i.admin_email}</span>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    )
  }

  renderDetails() {
    const { item } = this.state

    return (
      <form>
        <div className="form-group row">
           <label htmlFor="name" className="col-sm-2 col-form-label">Naam</label>
           <div className="col-sm-10">
             <input type="text" className="form-control" id="list-name" name="name" placeholder="Naam" value={item.name} onChange={this.onChangeItem} />
           </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-10 offset-sm-2">
            <button type="submit" className="btn btn-primary btn-block">Bewaren</button>
          </div>
        </div>
      </form>
    )
  }

  renderProducts() {
    const { items, disabled, onDeleteItem, query, searching, showSearchResults, searchResults, onAddToWishList } = this.state

    const totalValue = items.map(i => +i.fullPrice * +i.quantity).reduce((a,b) => a+b, 0)
    const soldValue = items.map(i => +i.fullPrice * +i.quantity_sold).reduce((a,b) => a+b, 0)

    return (
      <>
        <div className="row mb-4 noprint">
          <div className="col-6">
            <div className="card bg-info text-white">
              <div className="card-body text-center p-2">
                <h5>Totaal</h5>
                <h3>{formatPrice(totalValue)}</h3>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card bg-success text-white">
              <div className="card-body text-center p-2">
                <h5>Totaal verkocht</h5>
                <h3>{formatPrice(soldValue)}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row mb-4 noprint">
          <div className="col-12">
            <form className={`form-inline`} onSubmit={this.onSearch}>
              <div className="input-group my-1 w-100">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fas fa-plus mr-1"></i>Producten toevoegen
                  </span>
                </div>
                <input type="text" name="query" value={query} className="form-control" placeholder="Zoek op product" onChange={this.onChange} disabled={disabled} />
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" type="submit" disabled={disabled}>
                    <i className={`fas ${searching ? 'fa-spinner fa-spin' : 'fa-search'}`}></i> Zoeken
                  </button>
                </div>
                <div className="input-group-append">
                  <button className="btn btn-outline-secondary" type="button" disabled={disabled} onClick={this.onCloseSearch}>
                    <i className={`fas fa-times`}></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {showSearchResults ? (
          <div className="row">
            {searchResults ? searchResults.map((product, idx) => (
              <div className="col-3" key={`search-result-${idx}`}>
                <div className="card">
                  {product.id_default_image ? (
                    <img src={`https://assets.shopupnorth.com/p/${product.id_default_image.toString().split('').join('/')}/${product.id_default_image}-home_default.jpg`} className="card-img-top" alt={product.name} />
                  ) : null}
                  <div className="card-body">
                    <div className="mb-1">{product.name}{product.combination ? `: ${product.combination}` : ''}</div>
                    <button className="btn btn-outline-primary" onClick={e => this.onAddToWishList(e, idx, product)} disabled={disabled}>
                      <i className={`fas ${onAddToWishList === +idx ? 'fa-spinner fa-spin' : 'fa-plus'}`}></i> Toevoegen
                    </button>
                  </div>
                </div>
              </div>
            )) : null}
          </div>
        ) : (
          <>
            <GiftRegistryProducts
              items={items}
              disabled={disabled}
              onDeleteItem={onDeleteItem}
              deleteItem={this.onDeleteItem} />
          </>
        )}
      </>
    )
  }

  renderOrders() {
    const { orders, carriers, orderstates } = this.state

    const total = orders.length > 0
      ? orders.map(i => i.total_paid).reduce((a,b) => +a + +b)
      : 0
    const lastOrderDate = orders.length > 0
      ? orders.map(i => new Date(i.date_add)).reduce((a,b) => a > b ? a : b)
      : null

    return (
      <>
        <div className="row mb-3">
          <div className="col-md-4">
            <div className="card bg-light">
              <div className="card-body">
                <h5 className="card-title mb-0">{orders.length} bestellingen</h5>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card bg-light">
              <div className="card-body">
                <h5 className="card-title mb-0">&euro;{Number(total).toFixed(2)} totaal</h5>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card bg-light">
              <div className="card-body">
                <h5 className="card-title mb-0">{lastOrderDate ? `Laatste bestelling op ${DateFormat(lastOrderDate, "dd/mm/yyyy")}` : `Nog geen bestelling`}</h5>
              </div>
            </div>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>Referentie</th>
              <th>Datum</th>
              <th>Verzending</th>
              <th>Status</th>
              <th>Klant</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders ? orders.map(order => {
              const carrier = carriers.find(i => +i.id === +order.id_carrier)
              const orderstate = orderstates.find(i => +i.id === +order.current_state)
              const date_add = new Date(order.date_add)

              return (
                <tr key={`giftregistry-order-${order.id_order}`}>
                  <td>{order.reference}<br /><span className="badge badge-primary">&euro;{Number(order.total_paid).toFixed(2)}</span></td>
                  <td>{DateFormat(date_add, "dd/mm/yyyy")}</td>
                  <td>{carrier ? `${carrier.name}<br />${carrier.delay.find(i => i.id === '4').value}` : ''}</td>
                  <td><span className="badge" style={{ backgroundColor: orderstate.color }}>{orderstate.name.find(i => i.id === '4').value}</span></td>
                  <td>{order.firstname} {order.lastname}</td>
                  <td>
                    <button className="btn btn-primary btn-sm" onClick={this.onSendOrderConfMailToList} data-order={order.id_order} data-customer={order.id_customer}>
                      <i className="fas fa-paper-plane"></i>  Email opnieuw sturen
                    </button>
                  </td>
                </tr>
              )
            }) : null}
          </tbody>
        </table>
      </>
    )
  }
}

GiftRegistryDetailsWidget.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func
}

export default GiftRegistryDetailsWidget
