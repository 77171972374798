import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import DateFormat from 'dateformat'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import giftregistryAPI from '../../../services/giftregistry'

class GiftRegistryListWidget extends React.Component {
  context = null

  state = {
    items: null,
    selected: null,
    loading: false
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      giftregistryAPI.getList(this.context).then(items => this.setState({ items: items, loading: false }))
    })
  }

  onSelected = (e) => {
    e.preventDefault()

    const id = +e.target.getAttribute('data-id')
    this.setState({ selected: id }, () => {
      if (this.props.onSelected) this.props.onSelected(id)
    })
  }

  render() {
    const { items, loading } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Cadeaulijsten`} display={3} loading={loading}>
              <table className="table">
                <thead>
                  <tr>
                    <th>Naam</th>
                    <th>Klant</th>
                    <th>Datum</th>
                    <th>Actief</th>
                    <th>Beschermd</th>
                  </tr>
                </thead>
                <tbody>
                  {items ? items.map(item => {
                    const date_add = new Date(item.date_add)

                    return (
                      <tr key={`giftregistry-${item.id_wishlist}`}>
                        <td>
                          <Link to={`/`} className={``} key={`giftregistry-${item.id_wishlist}`} onClick={this.onSelected} data-id={item.id_wishlist}>
                            {item.name}
                          </Link>
                        </td>
                        <td>{item.firstname} {item.lastname}</td>
                        <td>{DateFormat(date_add, "dd-mm-yyyy")}</td>
                        <td>
                          {item.published ? (
                            <span className="badge badge-success"><i className="fas fa-check-circle"></i> Actief</span>
                          ) : (
                            <span className="badge badge-danger"><i className="fas fa-times-circle"></i> Niet actief</span>
                          )}
                        </td>
                        <td>
                          {item.access ? (
                            <i className="fas fa-lg fa-lock"></i>
                          ) : (
                            <i className="fas fa-lg fa-lock-open"></i>
                          )}
                        </td>
                      </tr>
                    )
                  }) : null}
                </tbody>
              </table>
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

GiftRegistryListWidget.propTypes = {
  onSelected: PropTypes.func
}

export default GiftRegistryListWidget
