import React from 'react'
import PropTypes from 'prop-types'

import {
  ProductSearchWidgetQuickEditQuantity,
  ProductSearchWidgetQuickEditTitle,
  ProductSearchWidgetQuickEditPrice,
  ProductSearchWidgetQuickEditAvailable } from '../../..'

class ProductSearchWidgetQuickEdit extends React.Component {
  render() {
    const { type } = this.props

    if (type === 1) return <ProductSearchWidgetQuickEditQuantity {...this.props} />
    if (type === 2) return <ProductSearchWidgetQuickEditTitle {...this.props} />
    if (type === 3) return <ProductSearchWidgetQuickEditPrice {...this.props} />
    if (type === 4) return <ProductSearchWidgetQuickEditAvailable {...this.props} />
    return null
  }
}

ProductSearchWidgetQuickEdit.propTypes = {
  product: PropTypes.object,
  type: PropTypes.number,
  context: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  toggleDisabled: PropTypes.func
}

export default ProductSearchWidgetQuickEdit
