import React from 'react'
import PropTypes from 'prop-types'

class NavbarDropdown extends React.Component {
  state = {
    open: false
  }

  onToggle = (e) =>{
    e.preventDefault()
    this.setState({ open: this.state.open ? false : true })
  }

  render() {
    const { children, title, className } = this.props
    const { open } = this.state

    return (
      <li className={`nav-item dropdown ${className} ${open ? 'show' : ''}`}>
        <a className="nav-link dropdown-toggle" href="/" role="button" onClick={this.onToggle}>{title}</a>
        <div className={`dropdown-menu ${open ? 'show' : ''}`}>
          {children}
        </div>
      </li>
    )
  }
}

NavbarDropdown.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node,
  className: PropTypes.string
}

export default NavbarDropdown
