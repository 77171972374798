import React from 'react'

import { Widget } from '../..'
import { getJewelleryLabelUrl } from '../../../services/printer'

class PrinterJewelleryLabelWidget extends React.Component {
  state = {
    price: null,
    code: null
  }

  onChangePrice = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onChangeCode = (e) => {
    e.preventDefault()
    this.setState({ [e.target.name]: e.target.value })
  }

  onPrint = (e) => {
    const { price, code } = this.state

    e.preventDefault()
    window.open(getJewelleryLabelUrl(price, code), "_blank")
    return false
  }

  footer() {
    return (<p>printen via 11351 (54 by 22mm) en bij Paper Feed de optie Left Roll aanduiden</p>)
  }

  render() {
    return (
      <Widget title={`Juwelen Label`} footer={this.footer()}>
        <form className="">
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">€</span>
            </div>
            <input type="text" className="form-control" name="price" placeholder="prijs" onChange={(e) => this.onChangePrice(e)} />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text">€</span>
            </div>
            <input type="text" className="form-control" name="code" placeholder="code" onChange={(e) => this.onChangeCode(e)} />
          </div>
          <button className="btn btn-primary btn-block" type="button" onClick={(e) => this.onPrint(e)}>
            <i className="fas fa-print"></i> print
          </button>
        </form>
      </Widget>
    )
  }
}

export default PrinterJewelleryLabelWidget
