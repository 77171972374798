import React from 'react'
import { navigate } from 'gatsby'
import queryString from 'query-string'
import { Link } from 'gatsby'

import { PageLayout, OrderListWidget, OrderDetailsWidget, Navbar } from '../..'
import AppContext from '../../../context/App/Context'

const DISPLAY_OPENORDERS = 0
// const DISPLAY_OPENCARTS = 1
const DISPLAY_ORDER = 2

const pills = [
  { id: DISPLAY_OPENORDERS, title: 'Orders', icon: 'fa-list'}
]

class OrderPage extends React.Component {
  context = null

  state = {
    // data
    display: DISPLAY_OPENORDERS,
    id_order: null
  }

  setDisplay = (e, display) => {
    if (e) e.preventDefault()
    this.setState({ display: display ? +display : DISPLAY_OPENORDERS })
  }

  showOrder = (e, order) => {
    if (e) e.preventDefault()
    navigate(`/orders?id=${order.id_order}`)
  }

  CheckAndLoadOrder = () => {
    const { display, id_order } = this.state
    const params = queryString.parse(window.location.search)
    if (params.id && !id_order && display === DISPLAY_OPENORDERS) {
      this.setState({ id_order: params.id, display: DISPLAY_ORDER })
    } else if (!params.id && display === DISPLAY_ORDER) {
      this.setState({ id_order: null, display: DISPLAY_OPENORDERS })
    }
  }

  componentDidUpdate(prevProps) {
    this.CheckAndLoadOrder()
  }

  componentDidMount() {
    this.CheckAndLoadOrder()
  }

  render() {
    const { display, id_order } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <PageLayout>
              <div className="row mb-4">
                <div className="col-12">
                  <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <ul className="navbar-nav mr-auto">
                      {pills.map(i => (
                        <li className={`nav-item ${display === i.id ? 'active' : ''}`} key={`nav-pill-${i.id}`}>
                          <Link className={`nav-link`} to={`/orders`}>
                            <i className={`fas ${i.icon}`}></i> {i.title}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="row">
                {display === DISPLAY_ORDER ? (
                  <div className={`col-12`}>
                    <OrderDetailsWidget context={context} id={id_order} />
                  </div>
                ) : null}
                <div className={`col-12 ${display === DISPLAY_OPENORDERS ? 'd-block' : 'd-none'}`}>
                  <OrderListWidget />
                </div>
              </div>
            </PageLayout>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default OrderPage
