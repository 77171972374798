import React from 'react'
import PropTypes from 'prop-types'

import { BrandStatusBadge } from '../..'

import manufacturerAPI from '../../../services/manufacturer'

class BrandDetailsWidgetTabDetails extends React.Component {
  state = {
    brand: null
  }

  componentDidMount() {
    const { context, id, setLoading } = this.props

    setLoading(true, () => {
      manufacturerAPI.get(context, id).then(brand => {
        this.setState({ brand: brand })
        setLoading(false)
      })
    })
  }

  render() {
    const { brand } = this.state

    if (!brand) return <></>

    return (
      <form>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Naam</label>
          <div className="col-sm-10">
            <input type="text" readOnly={true} className="form-control-plaintext" value={brand.name} />
          </div>
        </div>
        <div className="form-group row">
          <label className="col-sm-2 col-form-label">Status</label>
          <div className="col-sm-10">
            <BrandStatusBadge brand={brand} />
          </div>
        </div>
      </form>
    )
  }
}

BrandDetailsWidgetTabDetails.propTypes = {
  context: PropTypes.object,
  id: PropTypes.number,
  setLoading: PropTypes.func
}

export default BrandDetailsWidgetTabDetails
