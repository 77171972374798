import { callAPI } from './api'

export function getList(context, page) {
  return callAPI(context, '/carts', 'GET', { page: page && page > 0 ? page : 1 }).then(result => {
    return result ? result : null
  })
}

export function completeOrder(context, id) {
  return callAPI(context, `/carts/${id}/order`, 'POST').then(result => {
    return result ? result : null
  })
}

export default {
  getList,
  completeOrder
}
