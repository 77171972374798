import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

import { Navbar } from '../..'

class BrandNavbar extends React.Component {
  render() {
    return (
      <Navbar
        title="fa-copyright"
        link="/merken"
        setSelected={this.props.setSelected}
        items={[
          { title: 'Merken', link: '/merken' },
          { title: 'Rapportage', link: '/merken/rapportage' }
        ]} />
    )
  }
}

BrandNavbar.propTypes = {
  setSelected: PropTypes.func
}

export default BrandNavbar
