import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { Widget, BrandStatusBadge } from '../..'
import manufacturerAPI from '../../../services/manufacturer'

class BrandListWidget extends React.Component {
  state = {
    loading: false,
    items: null
  }

  componentDidMount() {
    const { context } = this.props

    this.setState({ loading: true }, () => {
      manufacturerAPI.getList(context).then(items => {
        this.setState({ loading: false, items: items })
      })
    })
  }

  render() {
    const { setSelected } = this.props
    const { loading, items } = this.state

    return (
      <Widget title={`Merken`} display={3} loading={loading}>
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: '5%' }}></th>
              <th>Naam</th>
              <th>Status</th>
              <th># Producten</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {items ? items.map(item => {
              return (
                <tr key={`manufacturer-${item.id_manufacturer}`}>
                  <td className="text-center">
                    <img src={`https://assets.shopupnorth.com/m/${item.id_manufacturer}.jpg`} className="img-fluid img-thumbnail" alt={item.name} />
                  </td>
                  <td>
                    <Link to={`/merken?id=${item.id_manufacturer}`} onClick={e => setSelected(item.id_manufacturer)}>
                      {item.name}
                    </Link>
                  </td>
                  <td>
                    <BrandStatusBadge brand={item} />
                  </td>
                  <td>
                    {item.num_products}
                  </td>
                  <td>
                    <a className="btn btn-sm btn-primary" href="/">
                      <i className="fas fa-file-excel"></i> exporteer naar excel
                    </a>
                  </td>
                </tr>
              )
            }) : null}
          </tbody>
        </table>
      </Widget>
    )
  }
}

BrandListWidget.propTypes = {
  context: PropTypes.object,
  setSelected: PropTypes.func
}

export default BrandListWidget
