import React from 'react'
import PropTypes from 'prop-types'

import { formatPrice } from '../../../utils/formatting'

class OrderDetailsWidgetTabProducts extends React.Component {
  render() {
    const { order } = this.props

    return (
      <>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-body">

                {order.associations.order_rows ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>Product</th>
                        <th>Prijs<br />inclusief btw</th>
                        <th>Aantal</th>
                        <th>Terugbetaald</th>
                        <th>Beschikbaar</th>
                        <th>Totaal</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {order.associations.order_rows.map(row => (
                        <tr key={`order-row-${row.id}`}>
                          <td></td>
                          <td>{row.product_name}</td>
                          <td>{formatPrice(row.unit_price_tax_incl)}</td>
                          <td>{row.product_quantity}</td>
                          <td></td>
                          <td></td>
                          <td>{formatPrice(row.product_quantity * row.unit_price_tax_incl)}</td>
                          <td></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : null}

              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

OrderDetailsWidgetTabProducts.propTypes = {
  order: PropTypes.object
}

export default OrderDetailsWidgetTabProducts
