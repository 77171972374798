import React from 'react'
import PropTypes from 'prop-types'

import { Widget } from '../..'
import BrandDetailsWidgetTabDetails from './tabDetails'

const TAB_DETAILS = 1
const TAB_PRODUCTS = 2

const tabs = [
  { id: TAB_DETAILS, title: 'Details', icon: '' },
  { id: TAB_PRODUCTS, title: 'Producten', icon: '' }
]

class BrandDetailsWidget extends React.Component {
  state = {
    loading: false,
    activeTab: TAB_DETAILS
  }

  onChangeTab = (e) => {
    e.preventDefault()
    const tabID = +e.target.getAttribute('data-tab')
    this.setState({ activeTab: tabID })
  }

  setLoading = (loading, callback) => {
    if (callback) {
      this.setState({ loading: loading }, callback())
    } else {
      this.setState({ loading: loading })
    }
  }

  widgetTitle() {
    const { activeTab } = this.state

    return (
      <>
        <ul className="nav nav-tabs mb-0" role="tablist">
          {tabs.map(tab => (
            <li className="nav-item" key={`nav-item-${tab.id}`}>
              <a className={`nav-link ${tab.id === activeTab ? 'active' : ''}`} href="/merken" data-tab={tab.id} onClick={this.onChangeTab} title={tab.title}>
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
      </>
    )
  }

  render() {
    const { loading, activeTab } = this.state
    const { context, id } = this.props

    let tab = <div></div>
    if (activeTab === TAB_DETAILS)
      tab = <BrandDetailsWidgetTabDetails context={context} id={id} setLoading={this.setLoading} />

    return (
      <Widget title={this.widgetTitle()} display={2} loading={loading}>
        {tab}
      </Widget>
    )
  }
}

BrandDetailsWidget.propTypes = {
  context: PropTypes.object,
  id: PropTypes.number
}

export default BrandDetailsWidget
