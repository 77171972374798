import React from 'react'

class PriceLabel extends React.Component {
  render() {
    const { value } = this.props

    const formattedValue = Number(Math.round(value * 100) / 100).toLocaleString('de-DE', { minimumFractionDigits: 2 })

    return (
      <span className="price">&euro; {formattedValue}</span>
    )
  }
}

export default PriceLabel
