import { callAPI } from './api'

const getProduct = (context, id, combination, language) =>
  callAPI(context, `/products/${id}/${combination ? combination : 0}`, 'GET', { language: language })

const updateProduct = (context, id, combination, product, stock, language) =>
  callAPI(context, `/products/${id}/${combination ? combination : 0}`, 'PUT', { product: product, stock: stock, language: language })

const updateProductField = (context, id, combination, field, value) =>
  callAPI(context, `/products/${id}/${combination ? combination : 0}/${field}`, 'PUT', { fieldValue: value })

const updateProductQuantity = (context, id, combination, value) =>
  callAPI(context, `/products/${id}/${combination ? combination : 0}/quantity`, 'PUT', { fieldValue: value })

const updateProductAvailable = (context, id, combination, available_now_en, available_now_nl, available_later_en, available_later_nl) =>
  callAPI(context, `/product-available/${id}`, 'PUT', {
    available_now_en: available_now_en,
    available_now_nl: available_now_nl,
    available_later_en: available_later_en,
    available_later_nl: available_later_nl
  })

export default {
  getProduct,
  updateProduct,
  updateProductField,
  updateProductQuantity,
  updateProductAvailable
}
