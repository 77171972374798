import React from 'react'
import Helmet from 'react-helmet'

import { LoginForm } from '../..'
import './loginpage.scss'

class LoginPage extends React.Component {
  render() {
    return (
      <>
        <Helmet title={'Up North POS'}>
          <body className="login" />
        </Helmet>
        <LoginForm />
        <p className="mt-5 mb-3 text-muted text-center">&copy; 2019</p>
      </>
    )
  }
}

export default LoginPage
