import React from 'react'
import { Link } from 'gatsby'

import { NavbarDropdown, ProductSearchForm } from '../..'

const app = require('../../../../package.json')

class PageHeader extends React.Component {
  logout = (e) => {
    e.preventDefault()
    this.props.context.onLogout()
  }

  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark flex-md-nowrap px-0 py-1 shadow noprint">
        <a className="navbar-brand col-sm-3 col-md-2" href="/">Up North {app.version}</a>

        <div className="col-sm-6 col-md-8">
          <ProductSearchForm className="form-inline" />
        </div>

        <div className="col-sm-3 col-md-2">
          <ul className="navbar-nav px-3">
            <NavbarDropdown title={<>Stefan Vunckx</>} className="ml-lg-4">
              <Link className="dropdown-item" to={`/`} onClick={this.logout}>Uitloggen</Link>
            </NavbarDropdown>
          </ul>
        </div>
      </nav>
    )
  }
}

export default PageHeader
