import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import DateFormat from 'dateformat'

import { Widget } from '../..'
import OrderDetailsWidgetTabDetail from './tabDetails'
import OrderDetailsWidgetTabProducts from './tabProducts'
import AppContext from '../../../context/App/Context'
import ordersAPI from '../../../services/order'
import { l } from '../../../utils/lang'
import { formatPrice } from '../../../utils/formatting'

const TAB_DETAILS = 0
const TAB_PRODUCTS = 1
const TAB_SHIPPING = 2
const TAB_PAYMENT = 3
const TAB_EMAILS = 4

const tabs = [
  { id: TAB_DETAILS, title: 'Details', icon: '' },
  { id: TAB_PRODUCTS, title: 'Producten', icon: '' },
  { id: TAB_SHIPPING, title: 'Verzending', icon: '' },
  { id: TAB_PAYMENT, title: 'Betaling', icon: '' },
  { id: TAB_EMAILS, title: 'E-mails', icon: '' },
]

class OrderDetailsWidget extends React.Component {
  state = {
    // data
    activeTab: TAB_DETAILS,
    order: null,
    // flags
    loading: false,
  }

  onClose = () => {
    navigate(`/orders`)
  }

  onChangeTab = (e) => {
    e.preventDefault()
    this.setState({ activeTab: +e.target.getAttribute('data-tab') }, () => {
      this.loadTabData()
    })
  }

  loadTabData = () => {
    const { id, context } = this.props
    const { activeTab } = this.state

    this.setState({ loading: true }, () => {
      ordersAPI.get(context, id).then(order => {
        this.setState({ loading: false, order: order })
      })
    })
  }

  componentDidMount() {
    this.loadTabData()
  }

  renderProducts = () => {
    const { order } = this.state

    if (order) {
      return <OrderDetailsWidgetTabProducts order={order} />
    } else {
      return <></>
    }
  }

  renderDetails = () => {
    const { order } = this.state

    if (order) {
      return <OrderDetailsWidgetTabDetail order={order} />
    } else {
      return <></>
    }
  }

  renderBody = () => {
    const { activeTab } = this.state
    if (activeTab === TAB_DETAILS) {
      return this.renderDetails()
    } else if (activeTab === TAB_PRODUCTS) {
      return this.renderProducts()
    }
    return activeTab
  }

  renderTitle = () => {
    const { activeTab, order } = this.state

    return (
      <>
        <ul className="nav nav-tabs mb-0" role="tablist">
          <li className="nav-item">
            <a className="nav-link disabled" href="/">{order ? order.reference : null}</a>
          </li>
          {tabs.map(tab => (
            <li className="nav-item" key={`nav-item-${tab.id}`}>
              <a className={`nav-link ${tab.id === activeTab ? 'active' : ''}`} href="/" data-tab={tab.id} onClick={this.onChangeTab} title={tab.title}>
                {tab.title}
              </a>
            </li>
          ))}
        </ul>
      </>
    )
  }

  render() {
    const { loading } = this.state
    const { onClose } = this.props

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={this.renderTitle()} loading={loading} display={2} onClose={this.onClose}>
              {this.renderBody()}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

OrderDetailsWidget.propTypes = {
  context: PropTypes.object
}

export default OrderDetailsWidget
