import React from 'react'
// import PropTypes from 'prop-types'
// import { Link } from 'gatsby'
// import DateFormat from 'dateformat'
import DatePicker from 'react-datepicker'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import voucherAPI from '../../../services/voucher'

const ERROR_VALUE = 1

const TYPE_AMOUNT = '1'
const TYPE_PERCENTAGE = '2'

class VoucherCreateWidget extends React.Component {
  context = null

  state = {
    reduction_type: TYPE_AMOUNT,
    value: 0,
    type: 'giftvoucher',
    date_to: new Date(new Date().getFullYear()+1, new Date().getMonth()+1, 1, 0, 0, 0),
    disabled: false,
    error: false,
    saving: false,
    changed: false,
    success: false
  }

  onChanged = (e) => this.setState({ [e.target.name]: e.target.value, changed: false, error: false })
  onChecked = (e) => this.setState({ [e.target.name]: e.target.checked, changed: false, error: false })
  onChangeDate = (date) => this.setState({ date_to: date })
  onReset = () => this.setState({
    error: false,
    saving: false,
    changed: false,
    success: false,
    disabled: false,
    date_to: new Date(new Date().getFullYear()+1, new Date().getMonth()+1, 1, 0, 0, 0),
    reduction_type: TYPE_AMOUNT,
    value: 0,
    type: 'giftvoucher',
  })

  onSave = (e) => {
    e.preventDefault()

    const { reduction_type, value, date_to, disabled } = this.state

    this.setState({ changed: true }, () => {
      if (!disabled) {
        if (+value > 0) {
          this.setState({ disabled: true, saving: true, changed: true }, () => {
            voucherAPI.createVoucher(this.context, reduction_type, value, date_to).then(result => {
              if (result && result.voucher) {
                this.setState({ disabled: false, saving: false, changed: false, success: result.voucher })
              } else {
                this.setState({ error: 0, changed: true })
              }
            })
          })
        } else {
          this.setState({ error: ERROR_VALUE, changed: true })
        }
      }
    })
  }

  render() {
    const loading = false

    const { reduction_type, value, type, date_to, disabled, saving, error, changed, success } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Voucher aanmaken`} loading={loading}>
              {success ? (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-success">
                      Gelukt!
                    </div>
                  </div>

                  <div className="col-6">
                    <h2>{success.code}</h2>
                  </div>

                  <div className="col-6">
                    <button className="btn btn-light" onClick={this.onReset}>
                      <i className="fas fa-redo"></i> nieuwe voucher
                    </button>
                  </div>
                </div>
              ) : (
                <form onSubmit={this.onSave}>
                  {error ? (
                    <div className="alert alert-danger">
                      Fout! Gelieve alle velden na te kijken.
                    </div>
                  ) : null}

                  <fieldset className="form-group">
                    <div className="row">
                      <legend className="col-form-label col-sm-2 pt-0">Type</legend>
                      <div className="col-sm-10">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="reduction_type" value={TYPE_AMOUNT} checked={reduction_type === TYPE_AMOUNT} onChange={this.onChanged} />
                          <label className="form-check-label">Korting via bedrag</label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="reduction_type" value={TYPE_PERCENTAGE} checked={reduction_type === TYPE_PERCENTAGE} onChange={this.onChanged} />
                          <label className="form-check-label">Korting via percentage</label>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="form-group row">
                    <label htmlFor="inputValue" className="col-sm-2 col-form-label">Waarde</label>
                    <div className="col-sm-10">
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          {reduction_type === TYPE_AMOUNT ? (
                            <span className="input-group-text">€</span>
                          ) : (
                            <span className="input-group-text">%</span>
                          )}

                        </div>
                        <input type="text" name="value" className={`form-control ${error === ERROR_VALUE ? 'is-invalid' : changed ? 'is-valid' : ''}`} id="inputValue" placeholder="Waarde" value={value} onChange={this.onChanged} />
                      </div>
                    </div>
                  </div>

                  <fieldset className="form-group">
                    <div className="row">
                      <legend className="col-form-label col-sm-2 pt-0">Type</legend>
                      <div className="col-sm-10">
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="type" id="gridRadios1" value="giftvoucher" checked={type === 'giftvoucher'} onChange={this.onChanged} />
                          <label className="form-check-label">Cadeaubon</label>
                        </div>
                      </div>
                    </div>
                  </fieldset>

                  <div className="form-group row">
                    <label htmlFor="inputDateTo" className="col-sm-2 col-form-label">Geldig tot</label>
                    <div className="col-sm-10">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <div className="input-group-text"><i className="fas fa-calendar-day"></i></div>
                        </div>
                        <DatePicker
                          id="inputDateTo"
                          className="form-control"
                          placeholderText="Geldig tot"
                          dateFormat="dd/MM/yyyy"
                          selected={date_to}
                          onChange={this.onChangeDate} />
                      </div>
                    </div>
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-8 offset-sm-2">
                      <button type="submit" className="btn btn-primary btn-block" disabled={disabled}>
                        <i className={saving ? `fas fa-spinner fa-spin` : `fas fa-check`}></i> Aanmaken
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

// VoucherCreateWidget.propTypes = {}

export default VoucherCreateWidget
