import React from 'react'
import PropTypes from 'prop-types'

import productAPI from '../../../../services/product'

class ProductSearchWidgetQuickEditTitle extends React.Component {
  state = {
    name_en: '',
    name_nl: '',
    ean13: '',
    reference: '',
    supplier_reference: '',
    syncing: false
  }

  constructor(props) {
    super(props)
    this.state.name_en = props.product.name_en
    this.state.name_nl = props.product.name_nl
    this.state.ean13 = props.product.ean13
    this.state.reference = props.product.reference
    this.state.supplier_reference = props.product.supplier_reference
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value})
  }

  onSubmit = (e) => {
    e.preventDefault()

    const { name_nl, name_en, ean13, reference, supplier_reference } = this.state
    const { disabled, toggleDisabled, onSuccess, context, product } = this.props
    if (!disabled) {
      this.setState({ syncing: true }, () => {
        toggleDisabled(true)

        productAPI.updateProductField(context, product.id_product, product.id_combination, 'name_en', name_en).then(() => {
          productAPI.updateProductField(context, product.id_product, product.id_combination, 'name_nl', name_nl).then(() => {
            productAPI.updateProductField(context, product.id_product, product.id_combination, 'ean13', ean13).then(() => {
              productAPI.updateProductField(context, product.id_product, product.id_combination, 'supplier_reference', supplier_reference).then(() => {
                productAPI.updateProductField(context, product.id_product, product.id_combination, 'reference', reference).then(result => {
                  this.setState({ syncing: false }, () => {
                    toggleDisabled(false)
                    if (result && result.product) {
                      onSuccess(result.product)
                    }
                  })
                })
              })
            })
          })
        })
      })
    }
  }

  render() {
    const { name_en, name_nl, ean13, reference, supplier_reference, syncing } = this.state
    const { onCancel, disabled } = this.props

    return (
      <form className="form" onSubmit={this.onSubmit}>
        <div className="row mb-1">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Titel EN</span>
              </div>
              <input autoFocus type="text" className="form-control" placeholder="Engels" name="name_en" value={name_en} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">Titel NL</span>
              </div>
              <input type="text" className="form-control" placeholder="Engels" name="name_nl" value={name_nl} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-4">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-barcode mr-1"></i>EAN</span>
              </div>
              <input type="text" className="form-control" placeholder="EAN" name="ean13" value={ean13} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-tag mr-1"></i>Code</span>
              </div>
              <input type="text" className="form-control" placeholder="Code" name="reference" value={reference} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
          <div className="col-4">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text"><i className="fas fa-tag mr-1"></i>Leverancierscode</span>
              </div>
              <input type="text" className="form-control" placeholder="Leverancierscode" name="supplier_reference" value={supplier_reference} onChange={this.onChange} disabled={disabled} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <button type="button" className="btn btn-secondary" onClick={onCancel} disabled={disabled}>
              <i className="fas fa-times"></i> annuleren
            </button>
            <button type="submit" className="btn btn-primary ml-1" disabled={disabled}>
              <i className={`fas ${syncing ? 'fa-spinner fa-spin' : 'fa-check'}`}></i> bewaren
            </button>
          </div>
        </div>
      </form>
    )
  }
}

ProductSearchWidgetQuickEditTitle.propTypes = {
  product: PropTypes.object,
  context: PropTypes.object,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
  toggleDisabled: PropTypes.func
}

export default ProductSearchWidgetQuickEditTitle
