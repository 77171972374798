import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import queryString from 'query-string'

import AppContext from '../../../context/App/Context'
import { PageLayout,
  GiftRegistryListWidget, GiftRegistryDetailsWidget, GiftRegistryHistoryWidget,
  Navbar } from '../..'

const DISPLAY_LIST = 0
const DISPLAY_HISTORY = 1

const pills = [
  { id: DISPLAY_LIST, title: 'Lijsten', icon: 'fa-gifts' },
  { id: DISPLAY_HISTORY, title: 'Geschiedenis', icon: 'fa-history' }
]

class GiftRegistryPage extends React.Component {
  context = null

  state = {
    selected: null,
    display: DISPLAY_LIST
  }

  onItemSelected = (id) => {
    if (id) {
      navigate(`/cadeaulijsten?id=${id}`)
    } else {
      navigate(`/cadeaulijsten`)
    }
  }

  onSetDisplay = (e, id) => {
    e.preventDefault()
    navigate(`/cadeaulijsten`)
    this.setState({ display: +id, selected: null })
  }

  CheckAndLoadGiftRegistry = () => {
    const { display, selected } = this.state
    const params = queryString.parse(window.location.search)
    if (display === DISPLAY_LIST) {
      if (params.id && selected != +params.id) {
        this.setState({ selected: +params.id })
      } else if (!params.id && selected) {
        this.setState({ selected: null })
      }
    }
  }

  componentDidUpdate(prevProps) {
    this.CheckAndLoadGiftRegistry()
  }

  componentDidMount() {
    this.CheckAndLoadGiftRegistry()
  }

  render() {
    const { selected, display } = this.state

    const showItem = selected > 0 ? true : false

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <PageLayout>
              <div className="row mb-4">
                <div className="col-12">
                  <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <ul className="navbar-nav mr-auto">
                      {pills.map(i => (
                        <li className={`nav-item ${display === i.id ? 'active' : ''}`} key={`nav-pill-${i.id}`}>
                          <a className={`nav-link`} href="/" onClick={e => this.onSetDisplay(e, i.id)}>
                            <i className={`fas ${i.icon}`}></i> {i.title}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
              {display === DISPLAY_LIST ? (
                <div className={`row`}>
                  <div className={`col-md-12 ${showItem ? '' : 'd-none'}`}>
                    {showItem ? <GiftRegistryDetailsWidget id={selected} onClose={this.onItemSelected} /> : null}
                  </div>
                  <div className={`col-md-12 ${showItem ? 'd-none' : ''}`}>
                    <GiftRegistryListWidget onSelected={this.onItemSelected} />
                  </div>
                </div>
              ) : null}
              {display === DISPLAY_HISTORY ? (
                <div className={`row`}>
                  <div className="col-12">
                    <GiftRegistryHistoryWidget />
                  </div>
                </div>
              ) : null}
            </PageLayout>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

GiftRegistryPage.propTypes = {
  className: PropTypes.string
}

export default GiftRegistryPage
