import React from 'react'
import PropTypes from 'prop-types'

class NotificationToast extends React.Component {
  render() {
    const { title, icon, content } = this.props

    return (
      <div className="toast fade show" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <i className={`${icon} mr-2`}></i>
          <strong className="mr-auto">{title}</strong>
          <small className="ml-1">11 mins ago</small>
          <button type="button" className="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="toast-body">{content}</div>
      </div>
    )
  }
}

NotificationToast.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.string
}

export default NotificationToast
