import { callAPI, apiURL } from './api'
import DateFormat from 'dateformat'

const createVoucher = (context, reduction_type, amount, date_to) =>
  callAPI(
    context,
    `/vouchers`,
    'POST',
    {
      date_to: date_to,
      reduction_percent: reduction_type === '2' ? amount : 0,
      reduction_amount: reduction_type === '1' ? amount : 0,
      active: 1
    }
  )

const generatePDF = (code, amount, date_to) => {
  const url = `${apiURL()}/pdf/voucher?code=${code}&reduction_amount=${Number(amount)}&date_to=${DateFormat(date_to, "mm/dd/yyyy")}`
  var win = window.open(url, '_blank')
  win.focus()
}


export default {
  createVoucher,
  generatePDF
}
