import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { Widget, StoreContext } from '../..'

class StoreDailySalesWidget extends React.Component {
  render() {
    const title = (
      <>
        <span className="float-left mt-2">Verkochte producten <u>gisteren</u> in de winkel</span>
        <button className="btn btn-primary float-right noprint" onClick={e => window.print()}>
          <i className="fas fa-print"></i> print
        </button>
      </>
    )

    return (
      <StoreContext.Consumer>
        {context => {
          const { loading, disabled, items } = context

          return (
            <Widget title={title} display={3} loading={loading}>
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '5%' }}></th>
                    <th>Aantal</th>
                    <th>Naam/Merk</th>
                    <th>Code/EAN</th>
                    <th>Beschikbaar</th>
                  </tr>
                </thead>
                <tbody>
                  {items ? items.map(item => {
                    return (
                      <tr key={`orderdetail-${item.id_order_detail}`}>
                        <td>
                          {item.id_default_image
                            ? <img src={`https://assets.shopupnorth.com/p/${item.id_default_image.toString().split('').join('/')}/${item.id_default_image}-home_default.jpg`} className="img-fluid" alt="" />
                            : null}
                        </td>
                        <td className="">
                          <span className={`badge ${+item.product_quantity > 1 ? 'badge-primary' : 'badge-secondary'}`}>{item.product_quantity}</span> verkocht
                        </td>
                        <td className="">
                          {item.product_name}<br />
                          {item.manufacturer_name}
                        </td>
                        <td className="">
                          {item.product_reference}<br />
                          {item.product_ean13}
                        </td>
                        <td className="">
                          <span className={`ml-2 badge ${+item.available_quantity > 0 ? 'badge-success' : 'badge-warning'}`}>{item.available_quantity}</span> beschikbaar
                        </td>
                      </tr>
                    )
                  }) : null}
                </tbody>
              </table>
            </Widget>
          )
        }}
      </StoreContext.Consumer>
    )
  }
}

StoreDailySalesWidget.propTypes = {

}

export default StoreDailySalesWidget
