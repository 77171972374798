import React from 'react'
import queryString from 'query-string'
import { navigate } from 'gatsby'

import { PageLayout, ProductSearchWidget, ProductNavbar, ProductEditWidget } from '../..'

class ProductPage extends React.Component {
  state = {
    selected: null,
    callback: null,
    create: false
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search)
    if (params && params.id) {
      this.setState({ selected: params.id })
    } else if (params && params.create) {
      this.setState({ create: true })
    }
  }

  goCreateProduct = (e) => {
    e.preventDefault();
    this.setState({ create: true, selected: null, callback: null }, () => {
      navigate(`/producten?create=1`)
    })
  }

  onSelectProduct = (id, cb) => {
    const { callback } = this.state

    this.setState({
      selected: id ? id : null,
      callback: cb ? cb : null,
      create: false
    }, () => {
      if (id) {
        navigate(`/producten?id=${id}`)
      } else {
        navigate(`/producten`)
        if (callback) callback()
      }
    })
  }

  render() {
    const { selected } = this.state

    return (
      <PageLayout>
        <div className="row">
          <div className="col-12 mb-2 text-right">
            <button className="btn btn-primary" onClick={this.goCreateProduct}>
              <i className="fas fa-plus"></i> nieuw product
            </button>
          </div>
          <div className={`col-md-12 ${selected ? '' : 'd-none'}`}>
            {selected ? <ProductEditWidget id={selected} onCancel={this.onSelectProduct} /> : null}
          </div>
          <div className={`col-md-12 ${selected ? 'd-none' : ''}`}>
            <ProductSearchWidget onSelectProduct={this.onSelectProduct} />
          </div>
        </div>
      </PageLayout>
    )
  }
}

export default ProductPage
