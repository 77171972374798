import React from 'react'
import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis, YAxis, Tooltip } from 'recharts'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import { getYTDMonthlyStatistics } from '../../../services/reporting'

class YTDChartWidget extends React.Component {
  context = null

  state = {
    loading: false,
    statistics: null
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      Promise.all([
        getYTDMonthlyStatistics(this.context)
      ]).then(data => {
        this.setState({ statistics: data[0], loading: false })
      })
    })
  }

  render() {
    const { statistics, loading } = this.state

    let totals = {
      total: 0,
      webshop: 0,
      bol: 0,
      geel: 0
    }

    let chartdata = []

    if (statistics) {
      statistics.reverse().slice(Math.max(chartdata.length - 13, 1)).forEach(item => {
        chartdata.push({ ...item, label: `${item.year}/${item.month}` })

        totals.total += +item.total
        totals.webshop += +item.webshops
        totals.bol += +item.shop5
        totals.geel += +item.shop6
      })
      statistics.reverse()
      chartdata = chartdata.slice(-14)
    }

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Statistieken`} loading={loading}>
              {loading ? <></> : (
                <ResponsiveContainer width="100%" height={250}>
                  <ComposedChart data={chartdata}>
                    <Bar dataKey="total" fill="#87CEEB" unit="&euro;" />
                    <Line type="monotone" dataKey="webshops" stroke="#00008B" name="webshop" unit="&euro;" />
                    <Line type="monotone" dataKey="shop5" stroke="#FF4500" name="bol.com" unit="&euro;" />
                    <Line type="monotone" dataKey="shop6" stroke="#2E8B57" name="winkel geel" unit="&euro;" />
                    <XAxis dataKey="label" />
                    <YAxis />
                    <Tooltip />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default YTDChartWidget
