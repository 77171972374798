import React from 'react'

class ProductEditWidgetDetails extends React.Component {
  render() {
    const {
      onProductChange, onStockChange, onProductChecked,
      disabled, visible,
      product, stock, tax_rules, manufacturers } = this.props

    return (
      <form className={visible ? 'd-block' : 'd-none'}>
        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fmanufacturer" className="col-sm-4 col-form-label text-right">Merk</label>
              <div className="col-sm-8">
                <select name="id_manufacturer" id="fmanufacturer" className="form-control" value={product.id_manufacturer} disabled={disabled} onChange={onProductChange}>
                  <option value=""></option>
                  {manufacturers.sort((a,b) => a.name.localeCompare(b.name)).map(m => (
                    <option value={m.id_manufacturer} key={`manufacturer-${m.id_manufacturer}`}>{m.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fean13" className="col-sm-4 col-form-label text-right">EAN code</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" value={product.ean13} id="fean13" name="ean13" placeholder="EAN code" disabled={disabled} onChange={onProductChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="freference" className="col-sm-4 col-form-label text-right">Referentie</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" value={product.reference} id="freference" name="reference" placeholder="Referentie" disabled={disabled} onChange={onProductChange} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fsupplier_reference" className="col-sm-4 col-form-label text-right">Leverancier Referentie</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" value={product.supplier_reference} id="fsupplier_reference" name="supplier_reference" placeholder="Leverancier referentie" disabled={disabled} onChange={onProductChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fsellprice" className="col-sm-4 col-form-label text-right">Verkoop Prijs (incl btw)</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" value={product.sellprice} id="fsellprice" name="sellprice" placeholder="Verkoopprijs (incl btw)" disabled={disabled} onChange={onProductChange} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fprice" className="col-sm-4 col-form-label text-right">Verkoop Prijs (excl btw)</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" value={product.price} id="fprice" name="price" placeholder="Verkoop Prijs (excl btw)" readOnly={true} disabled={disabled} onChange={onProductChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="ftaxrule" className="col-sm-4 col-form-label text-right">BTW tarief</label>
              <div className="col-sm-8">
                <select name="id_tax_rules_group" id="ftaxrule" className="form-control" value={product.id_tax_rules_group} disabled={disabled} onChange={onProductChange}>
                  {tax_rules.map(t => (
                    <option key={`taxrule-${t.id_tax_rules_group}`} value={t.id_tax_rules_group}>{t.name_nl}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fwholesale_price" className="col-sm-4 col-form-label text-right">Aankoop Prijs</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" value={product.wholesale_price} id="fwholesale_price" name="wholesale_price" placeholder="Aankoop Prijs" disabled={disabled} onChange={onProductChange} />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fquantity" className="col-sm-4 col-form-label text-right">Aantal</label>
              <div className="col-sm-8">
                <input type="text" className="form-control" name="quantity" value={stock.quantity} id="fquantity" placeholder="Aantal" disabled={disabled} onChange={onStockChange} />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row">
              <label htmlFor="fout_of_order" className="col-sm-4 col-form-label text-right">Wanneer niet op voorraad</label>
              <div className="col-sm-8">
                <select name="out_of_stock" value={product.out_of_stock} id="fout_of_order" className="form-control" disabled={disabled} onChange={onProductChange}>
                  <option value="0">Backorders niet toestaan</option>
                  <option value="1">Backorders toestaan</option>
                  <option value="2">Backorders toestaan (default)</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row">
              <div className="col-sm-4 text-right">
                <input className="form-check-input" type="checkbox" name="active" id="factive" value="1" checked={product.active} disabled={disabled} onChange={onProductChecked} />
              </div>
              <label htmlFor="factive" className="col-sm-8 form-check-label">Online</label>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row">
              <div className="col-sm-4 text-right">
                <input className="form-check-input" type="checkbox" name="available_for_order" id="favailable_for_order" value="1" checked={product.available_for_order} disabled={disabled} onChange={onProductChecked} />
              </div>
              <label htmlFor="favailable_for_order" className="col-sm-8 form-check-label">Beschikbaar om te bestellen</label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row">
              <div className="col-sm-4 text-right">
                <input className="form-check-input" type="checkbox" name="is_virtual" id="fis_virtual" value="1" disabled={disabled} onChange={onProductChecked} />
              </div>
              <label htmlFor="fis_virtual" className="col-sm-8 form-check-label">Virtueel Product</label>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group row">
              <div className="col-sm-4 text-right">
                <input className="form-check-input" type="checkbox" name="force_new" id="force_new" value="1" disabled={disabled} onChange={onProductChecked} />
              </div>
              <label htmlFor="force_new" className="col-sm-8 form-check-label">Nieuw</label>
            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default ProductEditWidgetDetails
