import React from 'react'
import { ResponsiveContainer, ComposedChart, Bar, Line, XAxis, YAxis, Tooltip } from 'recharts'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import { getDailyWebOrderStatistics } from '../../../services/reporting'

class DailyWebOrderStatisticsChartWidget extends React.Component {
  context = null

  state = {
    loading: false,
    statistics: null,
    lastyear: null
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      Promise.all([
        getDailyWebOrderStatistics(this.context)
      ]).then(data => {
        const result = data && data[0] ? data[0] : null
        this.setState({
          statistics: data && data[0] && data[0][0] ? data[0][0] : null,
          lastyear: data && data[0] && data[0][1] ? data[0][1] : null,
          loading: false
        })
      })
    })
  }

  render() {
    const { statistics, lastyear, loading } = this.state

    let chartdata_num = []
    let chartdata_sum = []

    if (statistics && statistics.length > 0) {
      const max = statistics.length - 1
      for (var i = max; i >= 0; i--) {
        chartdata_num.push({
          day: statistics[i].day,
          month: statistics[i].month,
          year: statistics[i].year,
          num_orders_now: statistics[i].num_orders,
          num_orders_lastyear: lastyear[i] ? lastyear[i].num_orders : 0,
          label: `${statistics[i].year}/${statistics[i].month}/${statistics[i].day}`
        })
      }
      for (var i = max; i >= 0; i--) {
        chartdata_sum.push({
          day: statistics[i].day,
          month: statistics[i].month,
          year: statistics[i].year,
          sum_orders_now: statistics[i].sum_orders,
          sum_orders_lastyear: lastyear[i] ? lastyear[i].sum_orders : 0,
          label: `${statistics[i].year}/${statistics[i].month}/${statistics[i].day}`
        })
      }
    }

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <div className="row">
              <div className="col-12 mb-2">
                <div className="alert alert-warning mb-0">
                  <i className="fas fa-exclamation-circle"></i> dit houdt geen rekening met retours of annulaties
                </div>
              </div>
              <div className="col-12 mb-2">
                <Widget title={`Aantal orders per dag`} loading={loading}>
                  <ResponsiveContainer width="100%" height={250}>
                    <ComposedChart data={chartdata_num}>
                      <Bar dataKey="num_orders_now" fill="#87CEEB" name="aantal" />
                      <Line type="monotone" dataKey="num_orders_lastyear" stroke="#FFA500" name="vorig jaar" />
                      <XAxis dataKey="label" />
                      <YAxis />
                      <Tooltip />
                    </ComposedChart>
                  </ResponsiveContainer>
                </Widget>
              </div>
              <div className="col-12 mb-2">
                <Widget title={`Totaal bedrag per dag`} loading={loading}>
                  {loading ? <></> : (
                    <ResponsiveContainer width="100%" height={250}>
                      <ComposedChart data={chartdata_sum}>
                        <Bar dataKey="sum_orders_now" fill="#87CEEB" name="bedrag" unit="&euro;" />
                        <Line type="monotone" dataKey="sum_orders_lastyear" stroke="#FFA500" name="vorig jaar" unit="&euro;" />
                        <XAxis dataKey="label" />
                        <YAxis unit="&euro;" />
                        <Tooltip />
                      </ComposedChart>
                    </ResponsiveContainer>
                  )}
                </Widget>
              </div>
            </div>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default DailyWebOrderStatisticsChartWidget
