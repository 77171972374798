import React from 'react'
import PropTypes from 'prop-types'

class Modal extends React.Component {
  onClose = (e) => {
    e.preventDefault()
    if (this.props.onClose) this.props.onClose()
  }

  render() {
    const { title, children, buttons } = this.props

    return (
      <div className="modal" tabindex="-1" role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
              <button type="button" className="close" onClick={this.onClose}>
                <span>&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {children}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={this.onClose}>Close</button>
              {buttons}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func,
  buttons: PropTypes.node
}

export default Modal
