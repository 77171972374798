import React from 'react'
// import DateFormat from 'dateformat'
import DatePicker from 'react-datepicker'

import { Widget } from '../..'
import AppContext from '../../../context/App/Context'
import voucherAPI from '../../../services/voucher'

class VoucherGeneratePDFWidget extends React.Component {
  context = null

  state = {
    code: '',
    amount: 0,
    date_to: new Date(),
    loading: false,
    disabled: true,
    onSave: false
  }

  setDisabled = (s) => this.setState({
    disabled: ((s.code && s.code.length > 0) && (s.amount && +s.amount > 0) && s.date_to) ? false : true
  })

  onChanged = (e) => this.setState({ [e.target.name]: e.target.value }, () => this.setDisabled(this.state))

  onChangeDate = (date) => this.setState({ date_to: date })

  onSave = (e) => {
    e.preventDefault()

    const { code, amount, date_to } = this.state

    if (!this.state.disabled) {
      this.setState({ disabled: true, onSave: true }, () => {
        voucherAPI.generatePDF(code, amount, date_to)
        this.setState({ disabled: false, onSave: false })
      })
    }
  }

  render() {
    const { loading, amount, code, date_to, disabled, onSave } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Voucher PDF aanmaken`} loading={loading}>
              <form onSubmit={this.onSave}>
                <div className="form-group row">
                  <label htmlFor="inputValue" className="col-sm-2 col-form-label">Code</label>
                  <div className="col-sm-10">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">code</span>
                      </div>
                      <input type="text" name="code" className="form-control" placeholder="Waarde" value={code} onChange={this.onChanged} />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="inputValue" className="col-sm-2 col-form-label">Waarde</label>
                  <div className="col-sm-10">
                    <div className="input-group mb-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">€</span>
                      </div>
                      <input type="text" name="amount" className="form-control" placeholder="Waarde" value={amount} onChange={this.onChanged} />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <label htmlFor="inputDateTo" className="col-sm-2 col-form-label">Geldig tot</label>
                  <div className="col-sm-10">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text"><i className="fas fa-calendar-day"></i></div>
                      </div>
                      <DatePicker
                        id="inputDateTo"
                        className="form-control"
                        placeholderText="Geldig tot"
                        dateFormat="dd/MM/yyyy"
                        selected={date_to}
                        onChange={this.onChangeDate} />
                    </div>
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-sm-8 offset-sm-2">
                    <button type="submit" className="btn btn-primary btn-block" disabled={disabled}>
                      <i className={onSave ? `fas fa-spinner fa-spin` : `fas fa-check`}></i> Aanmaken
                    </button>
                  </div>
                </div>
              </form>
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default VoucherGeneratePDFWidget
