import { callAPI } from './api'

const updateStock = (context, product, quantity, out_of_stock) =>
  callAPI(
    context,
    `/stock/${product.id_product}/${+product.id_product_attribute > 0 ? product.id_product_attribute : 0}`,
    'PUT',
    {
      quantity: quantity,
      out_of_stock: out_of_stock
    }
  )

export default {
  updateStock
}
