import React from 'react'
import PropTypes from 'prop-types'

class BrandStatusBadge extends React.Component {
  render() {
    const { brand } = this.props

    return brand.active
      ? <span className="badge badge-success mt-2">Actief</span>
      : <span className="badge badge-danger mt-2">Inactief</span>
  }
}

BrandStatusBadge.propTypes = {
  brand: PropTypes.object
}

export default BrandStatusBadge
