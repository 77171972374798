import React from 'react'

import { PageLayout } from '../..'

class DashboardPage extends React.Component {
  render() {
    return (
      <PageLayout>
      </PageLayout>
    )
  }
}

export default DashboardPage
