import React from 'react'

import { Widget, PriceLabel } from '../..'
import AppContext from '../../../context/App/Context'
import { getYTDMonthlyStatistics } from '../../../services/reporting'

class YTDHistoryWidget extends React.Component {
  context = null

  state = {
    loading: false,
    statistics: null
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      Promise.all([
        getYTDMonthlyStatistics(this.context)
      ]).then(data => {
        this.setState({ statistics: data[0], loading: false })
      })
    })
  }

  render() {
    const { statistics, loading } = this.state

    return (
      <AppContext.Consumer>
        {context => {
          this.context = context

          return (
            <Widget title={`Historiek`} loading={loading} display={3}>
              {loading ? <></> : (
                <table className="table table-sm table-striped table-hover mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Datum</th>
                      <th scope="col">Totaal</th>
                      <th scope="col">Webshop</th>
                      <th scope="col">BOL.COM</th>
                      <th scope="col">Winkel Geel</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statistics ? statistics.map((item, idx) => (
                      <tr key={`item-${idx}`}>
                        <td className="">{item.year} / {item.month}</td>
                        <td className=""><PriceLabel value={item.total} /></td>
                        <td className=""><PriceLabel value={item.webshops} /></td>
                        <td className=""><PriceLabel value={item.shop5 ? item.shop5 : 0} /></td>
                        <td className=""><PriceLabel value={item.shop6 ? item.shop6 : 0} /></td>
                      </tr>
                    )) : null}
                  </tbody>
                </table>
              )}
            </Widget>
          )
        }}
      </AppContext.Consumer>
    )
  }
}

export default YTDHistoryWidget
