import React from 'react'

class ProductRowView extends React.Component {
  render() {
    const { product } = this.props

    return (
      <div className={`row mx-0`}>
        <div className="col-1 px-0">
          {product.id_default_image ? (
            <img src={`https://assets.shopupnorth.com/p/${product.id_default_image.toString().split('').join('/')}/${product.id_default_image}-home_default.jpg`} className="img-fluid" alt="" />
          ) : null}
        </div>
        <div className="col-6">
          <h5 className="">
            #{product.id_product} {product.combination ? `${product.combination} - ` : null}{product.name}
            <span className={`badge ml-2 ${product.quantity > 0 ? 'badge-success' : 'badge-danger'}`}>{product.quantity}</span>
          </h5>
          <h6 className="">
            {product.name_manufacturer}
          </h6>
        </div>
        <div className="col-2">
          <h4 className="">&euro; {Number(product.fullPrice).toFixed(2)}</h4>
          <p className="">&euro; {Number(product.price).toFixed(2)} excl btw</p>
        </div>
        <div className="col-3 text-right">
          <button className="btn btn-primary">
            <i className="fas fa-print"></i>
          </button>
        </div>
      </div>
    )
  }
}

export default ProductRowView
